<template lang="pug">
  v-data-table(
    :headers="headers",
    :items="$store.state.tireModel.tireModel_data",
    item-key="id"
    :loading="loading",
    :single-select="false"
    show-select
    v-model="selectedTireModels"
  )
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')
    template(v-slot:item.name="{item}")
      .d-flex.align-center.tyreComponent.py-1()
        image-thumbnail.mr-3(v-for="(itemID,index) in item.photos_id" :key="index" :width='60', :height='60', :src="itemID")
        .d-flex.flex-column()
          .font-weight-bold() {{item.name}}
          .caption.primary--text.font-weight-bold() {{item.size}}
          .caption.grey--text.text--darken-1() {{item.pattern}} [{{item.load_index}}/{{item.speed_rating}}]
    template( v-slot:item.merchant_id="{ item }")
      merchant-column-by-id(v-if="item.merchant_id" :merchant_id="item.merchant_id")

    template( v-slot:item.action="{ item }")
      v-row.mr-2(justify="end")
        more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item' )

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import MerchantColumnById from "@/modules/global/components/table/MerchantColumnById";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  props: ["loading"],
  components: {
    MoreDropDownMenu,
    MerchantColumnById,
    ImageThumbnail
  },
  computed:{
    selectedTireModels: {
      get: function () {
        return this.$store.getters["tireModel/getSelectedTireModels"];
      },
      set: function (value) {
        this.$store.commit("tireModel/setSelectedTireModel", value);
      }
    },
  },

  data: () => ({

    headers: [
      {
        text: "Name",
        value: "name",
        align: "left"
      },

      {
        text: "Width",
        value: "width",
        align: "center"
      },
      {
        text: "Aspect Ratio",
        value: "aspect_ratio",
        align: "center"
      },
      {
        text: "Radius",
        value: "rim_diameter",
        align: "center"
      },
      {
        text: "*Tire Supplier",
        value: "merchant_id",
        align: "left"
      },
      {
        text: "*Cost",
        value: "cost",
        align: "center"
      },
      {
        text: "*Warranty Period",
        value: "warranty_period",
        align: "center"
      },
      {
        text: "*Recommended Retreads",
        value: "recommended_retreads",
        align: "center"
      },

      {text: "Action", value: "action", tooltip: "Edit", align: "right"}



    ],
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),

  methods: {
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("tireModel/setCurrentIndexByID", value.theObject.id);



          this.$router.push({
            name: "TireModelForm",
            params: {inspectionID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("tireModel/deleteTireModel", value.theObject.id);
          break;
      }
    }
  },

}
</script>



<style scoped lang="stylus">
.tireMerchant {
  border 1px solid #CCCCCC;
}

</style>