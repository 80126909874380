import {getField, updateField} from "vuex-map-fields";
import devices from "@/modules/vehicles/store/devices";
import assignments from "@/modules/vehicles/store/assignments";

const VEHICLES_API = "/vehicle";
import Repository from "@/repository";

// ----------------------------------------------------------- //
// ----------------------- STATE ------------------------- //
// ----------------------------------------------------------- //
const state = {
    all: [],
    currentIndex: -1,
    newItem: false,
    decodedVehicleList: []
};

// ----------------------------------------------------------- //
// ------------------------- GETTERS ------------------------- //
// ----------------------------------------------------------- //
const getters = {
    getItem: state => {
        return getField(state.all[state.currentIndex]);
    },

    getCurrentItem: state => {
        return state.all.find(item => item.id == state.all[state.currentIndex].id);
    },

    getVehicles: state => {
        return [state.all];
    },

    getTirePattern: state => {
        if (state.all[state.currentIndex].tire_pattern == null){
            return {axles: [], spares: null}
        }
        return state.all[state.currentIndex].tire_pattern
    },

    getVehicleByID: state => vehicleID => {
        return state.all.find((item) => item.id == vehicleID);
    },

    getThumbnailByVehicleID: (state, getters) => vehicleID => {
        let vehicle = getters.getVehicleByID(vehicleID);

        let photoObject = vehicle.data.photos.find(function (item) {
            return item.id == vehicle.thumbnail_id;
        });

        return photoObject.thumbnail_id;
    },

    getAssignedVehicles: state => {
        let vehicles = state.all.filter(vehicle => {
            return vehicle.operator != null;
        });
        return vehicles;
    },

    getStatus: state => status => {
        return state.all.filter(vehicle => vehicle.status == status);
    },

    getStatusColor: () => status => {
        let color;
        switch (status) {
            case "active":
                color = "#CCCCCC";
                break;
            case "in_active":
                color = "#E6E6E6";
                break;
            case "in_shop":
                color = "#E6E6E6";
                break;
            case "out_of_service":
                color = "#E6E6E6";
                break;
            case "sold":
                color = "#E6E6E6";
                break;
            default:
                color = "#E6E6E6";
        }
        return color;
    }
};

// ----------------------------------------------------------- //
// ----------------------- MUTATIONS ------------------------- //
// ----------------------------------------------------------- //
const mutations = {
    updateItem(state, payload) {
        updateField(state.all[state.currentIndex], payload);
    },

    deleteImage(state, payload) {
        let index = state.all[state.currentIndex].images.findIndex(
            item => item === payload
        );
        state.all[state.currentIndex].images.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.all[state.currentIndex].documents.findIndex(
            item => item === payload
        );
        state.all[state.currentIndex].documents.splice(index, 1);
    },

    makeThumbnail(state, payload) {
        state.all[state.currentIndex].thumbnail_id = payload;
    },

    setCurrentIndex(state, payload) {
        // console.log(
        //   `setting current index to ID: ${payload.id} and item id is: ${
        //     state.all[0]
        //   }`
        // );
        state.currentIndex = state.all.findIndex(item => item.id == payload.id);
        console.log(`neww index iss : ${state.currentIndex}`);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.all.findIndex(
            vehicle => vehicle.id == theID
        );
    },

    setCurrentItem(state, payload) {
        state.all.splice(state.currentIndex, 1, payload);
        state.newItem = false;
    },

    deleteCurrentItem(state) {
        let toDeleteIndex = state.currentIndex;
        (state.currentIndex = 1), state.all.splice(toDeleteIndex, 1);
        state.newItem = false;
    },

    cancelItem(state) {
        state.all.splice(state.currentIndex, 1);
        state.newItem = false;
        state.currentIndex = state.all.length > 0 ? 0 : -1;
    },

    loadAll: (state, payload) => {
        // console.log(payload.data);
        payload.data.forEach(item => {
            item.value = false;
            if (item.data == null) {
                item.data = {};
            }
        });
        state.all = payload.data;
        state.currentIndex = 0;
        if (state.all.length) {
            state.all[0].data = {};
        }
    },

    fetchVehicle: (state, payload) => {
        state.currentIndex = 0;
        state.all.push(payload.data);
        if (state.all.length) {
            state.all[0].data = {};
        }
    },

    addItem(state, payload) {
        state.all.push(payload);
        state.currentIndex = state.all.length - 1;
        state.newItem = true;

        // if data is null set it to object
        if (state.all[state.currentIndex].data == null) {
            state.all[state.currentIndex].data = {};
        }
    },

    setThumbnail(state, payload) {
        state.all[state.currentIndex].thumbnail_id = payload.thumbnail_id;
    },

    dedcoded(state, payload) {
        state.decodedVehicleList.push(payload.data);
    },

    resetDecodedList() {
        state.decodedVehicleList = [];
    },

    saveComment(state, payload) {
        console.log(payload.data);
    },

    cleanBeforeSave(state) {
        // convert the following elements in selected array
        let integersArray = [
            "length",
            "width",
            "height",
            "wheel_base",
            "gross_weight",
            "max_payload",
            "engine_cylinders",
            "engine_displacement",
            "engine_horse_power",
            "transmission_gears",
            "tire_pressure",
            "fuel_mpg",
            "fuel_tank_capacity"
        ];

        integersArray.forEach(item => {
            if (state.all[state.currentIndex][item] != null) {
                try {
                    state.all[state.currentIndex][item] = parseInt(
                        state.all[state.currentIndex][item]
                    );
                } catch {
                    console.log(`error converting ${item}`);
                }
            }
        });

        state.all[state.currentIndex].length = parseInt(
            state.all[state.currentIndex].length
        );
    },

    reset(state) {
        state.all = []
        state.currentIndex = -1
        state.newItem = false
        state.decodedVehicleList = []
    },

    saveTirePattern(state, payload) {
        state.all[state.currentIndex].tire_pattern = payload;
    }
};

// ----------------------------------------------------------- //
// ------------------------- ACTIONS ------------------------- //
// ----------------------------------------------------------- //
const actions = {
    async loadAllItems({commit}) {
        try {
            commit("loadAll", await Repository.get(`${VEHICLES_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async fetchVehicle({commit}, payload) {
        try {
            commit(
                "fetchVehicle",
                await Repository.get(`${VEHICLES_API}/${payload.id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteItem({commit}, payloadId) {
        try {
            commit(
                "deleteCurrentItem",
                await Repository.delete(`${VEHICLES_API}/${payloadId}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async saveItem({commit, getters}) {
        // clean the data before save
        commit("cleanBeforeSave");
        console.log(getters.getCurrentItem);
        try {
            console.log(`saving new item ${getters.getCurrentItem.id}`);
            console.log(getters.getCurrentItem);
            commit(
                "setCurrentItem",
                await Repository.post(`${VEHICLES_API}/`, getters.getCurrentItem)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async saveCurrentAndDoNothing({commit, getters}) {
        commit("cleanBeforeSave");
        Repository.post(`${VEHICLES_API}/`, getters.getCurrentItem);
    },

    async addNew({commit}) {
        await Repository.get(`${VEHICLES_API}/new`)
            .then(response => {
                commit("addItem", response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async decode({commit, getters}) {
        try {
            commit(
                "dedcoded",
                await Repository.post(`${VEHICLES_API}/decode`, getters.getCurrentItem)
            );
        } catch (error) {
            console.log(error);
        }
    }

    // async saveComment({ commit, getters }, payload) {
    //   try {
    //     commit(
    //       "saveComment",
    //       await Repository.post(`${VEHICLES_API}/comment`, {
    //         id: getters.getCurrentItem.id,
    //         title: payload.title,
    //         comment: payload.comment
    //       })
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        devices,
        assignments
    }
};
