import { render, staticRenderFns } from "./Wheel.vue?vue&type=template&id=b845f176&scoped=true"
import script from "./Wheel.vue?vue&type=script&lang=js"
export * from "./Wheel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b845f176",
  null
  
)

export default component.exports