<template lang="pug">
  div()
    v-container.pt-0(grid-list-sm)
      v-layout(row, wrap, justify-start)
       v-flex(xs6)
          form-divider() SUSPENSION
          v-layout(row, wrap, justify-start)
            v-flex(xs12)
              v-select(v-model='front_suspension', :items='suspensionTypes', label='Front Suspension Type', hint='Front Suspension Type', persistent-hint)
            v-flex(xs12)
              v-select(v-model='rear_suspension', :items='suspensionTypes', label='Rear Suspension Type', hint='Rear Suspension Type', persistent-hint)


          form-divider() BRAKES
          v-layout(row, wrap, justify-start)
            v-flex(xs12)
              v-select(v-model='front_brakes', :items='brakeSystemType', label='Front Brake System', hint='Description of Front Brake System', persistent-hint)
            v-flex(xs12)
              v-select(v-model='rear_brakes', :items='brakeSystemType', label='Rear Brake System', hint='Description of Rear Brake System', persistent-hint)


          form-divider() TIRES
          v-layout(row, wrap, justify-start)
            v-flex(xs12)
              v-text-field(v-model='tire_size', label='Tire Size', hint='Standard Tire Description e.g. P245/60R18', persistent-hint)
            v-flex(xs12)
              v-text-field(v-model='tire_pressure', label='Tire Pressure (PSI)', type='number', hint='Tire Air Pressure in PSI', persistent-hint)
       v-flex(xs6)
         .grey.lighten-2(:style="getPatternStyle()")
            axle-pattern-creator(:width="width" :height="height" :x="x" :y="y" :trackWidth="trackWidth")
      
</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import AxlePatternCreator from "@/modules/inventory/tire_management/components/ui/AxlePatternCreator";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "vehicles/getItem",
  mutationType: "vehicles/updateItem"
});

export default {
  components: {
    FormDivider,
    AxlePatternCreator
  },

  data: () => ({
    driveTypes: ["Rear Wheel Drive", "Front Wheel Drive"],
    brakeSystemType: ["Disk Brakes", "Drum Brakes"],
    suspensionTypes: ["Coil Springs", "Leaf Springs"],
    width : 500,
    height : 900,
    x: 125,
    y: 80,
    trackWidth: 200,

    // green, blue, orange, red, gray
  }),

  computed: {
    ...mapFields([
      "front_suspension",
      "rear_suspension",
      "front_brakes",
      "rear_brakes",
      "tire_size",
      "tire_pressure"
    ])
  },

  methods:{
    getPatternStyle: function(){
      let theStyle = {
        'border': '1px solid #CCCCCC',
        'border-radius': '10px',
      };
      theStyle.width = `${this.width}px`;
      theStyle.height = `${this.height}px`;

      return theStyle;
    }
  }
};
</script>

<style scoped>
.topiDivider {
  color: #aa00cc;
  border-bottom: 2px solid #aa00cc;
  margin-top: 20px;
  font-size: 18px;
}
</style>
