<template>
  <div >
    <svg :width="this.width" :height="this.height" xmlns="http://www.w3.org/2000/svg" color="#FF00FF">
      <!-- Front Bumper -->
      <g :transform="`translate(${(-trackWidth*0.5) + x}, ${0})`">
        <polyline  :points="getFrontBumper()" fill="none"  stroke-linecap="round" stroke="#333333"  stroke-width="18" />
        <g :transform="`translate(${(trackWidth*0.5)}, ${-10})`" @click="openAxleSelectDialog">
          <circle
              :cx="x"
              :cy="y"
              r="12"
              fill="#FFFFFF"
              stroke="#CCCCCC"
              stroke-width="1"
          />
          <line :x1="x-6" :x2="x+6" :y1="y" :y2="y" fill="none" stroke="black" stroke-width="4"></line>
          <line :x1="x" :x2="x" :y1="y-6" :y2="y+6" fill="none" stroke="black" stroke-width="4"></line>
        </g>
      </g>

      <!-- Wheel Axles -->
      <g :transform="`translate(${(-trackWidth*0.5) + x}, ${y+60})`" >
        <axle v-for="(item,index) in chasis.axles" :key="index" :x="x" :y="getYPos(index)" :track-width="trackWidth"  :tire-rotation="0" :wheel_configuration="item.wheel_configuration" @addAxle="openAxleSelectDialog" @removeAxle="onRemoveAxle(index)" @change="onChange" :index="index" :axle-type="item.axle_type"/>
      </g>

      <!-- Rear Bumper and Spares -->
      <g :transform="`translate(${(-trackWidth*0.5) + x}, ${(chasis.axles.length>0)?totalHeight:80})`">
        <polyline  :points="getRearBumper()" fill="none"  stroke-linecap="round"  stroke="#666666" :stroke-width="12"  />
        <g v-for="(item,index) in chasis.spares" :key="`spare_${index}`" :transform="`translate(${(trackWidth*0.5+40) }, ${(index*45)})`">
          <rect
            :x="x-100"
            :y="y-20"
            rx="8"
            ry="8"
            width="120"
            height="40"
            fill="#ffCC00"
          >
          </rect>
          <text :x="x-40" :y="y+5"  font-size="16" fill="black" text-anchor="middle">Spare {{index+1}}</text>
        </g>
        <g @click="addSpare"  :transform="`translate(${trackWidth*0.5 +80}, 0)`">
          <circle
              :cx="x"
              :cy="y"
              r="12"
              fill="#FFFFFF"
              stroke="#CCCCCC"
              stroke-width="1"
          />
          <line :x1="x-6" :x2="x+6" :y1="y" :y2="y" fill="none" stroke="black" stroke-width="4"></line>
          <line :x1="x" :x2="x" :y1="y-6" :y2="y+6" fill="none" stroke="black" stroke-width="4"></line>
        </g>
        <g @click="removeSpare"  :transform="`translate(${trackWidth*0.5 -80}, 0)`">
          <circle
              :cx="x"
              :cy="y"
              r="12"
              fill="#FFFFFF"
              stroke="#CCCCCC"
              stroke-width="1"
          />
          <line :x1="x-6" :x2="x+6" :y1="y" :y2="y" fill="none" stroke="orange" stroke-width="4"></line>

        </g>

      </g>

    </svg>
    <v-dialog v-model="dialogShow" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline2">
          Select an Axle Type
        </v-card-title>

        <v-card-text>
          <v-select v-model='selectedAxleType' item-text="name" item-value="id" :items='axleTypes' label='Select Axle Type'>
          </v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-layout justify-end>
            <v-btn class="ma-2" @click="dialogShow=false" small> cancel</v-btn>
            <v-btn class="ma-2" color="primary" @click="saveAxle" small> Add</v-btn>
          </v-layout>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>
<script>
import Axle from "@/modules/inventory/tire_management/components/ui/AxleComponents/Axle.vue";
export default {
  components: {
    Axle,
  },
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 600
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0
    },
    trackWidth: {
      type: Number,
      default: 320
    },

  },

  data: () => ({
    axleTypes: [{
      id: 'steer',
      name: 'Steer',
    },{
      id: 'drive',
      name: 'Drive',
    },{
      id: 'lift',
      name: 'Lift',
    }
    ],
    dialogShow: false,
    selectedAxleType: 'drive',
    chasis:{
      axles:[],
      spares:[],
    },
    axles:0,
    spares:0,
    rotationAngle: 30,

  }),
  computed:{
    totalHeight: {
      get: function() {
        let yPos = 0;
        // for (let i=0; i<this.chasis.axles.length-1; i++){
          yPos += this.chasis.axles.length * 180;
        return yPos;
      }
    },
    grandHeight: {
      get: function() {
        let yPos = this.totalHeight;

        let spareHeight = this.spares.length * 30;
        return yPos + spareHeight;

      }
    }
  },
  methods:{
    getYPos: function(index){
      let yPos =  index*180;

      return yPos;
    },

    getFrontBumper: function() {
      return [this.x-20,this.y,this.x,this.y-20,this.x+this.trackWidth,this.y-20,this.x+this.trackWidth+20,this.y]
    },

    getRearBumper: function() {
      return [this.x,this.y,this.x+this.trackWidth,this.y]
    },

    onClick1: function(){
      console.log('click 1')
    },
    onClick2: function(){
      console.log('click 2')
    },
    openAxleSelectDialog: function(){
      this.dialogShow = true;
    },
    saveAxle: function(){
      this.dialogShow = false;

      let axle = {
        axle_id: `A${this.chasis.axles.length+1}`,
        axle_type: this.selectedAxleType,

        wheel_configuration: null
      }

      this.$nextTick(() => {
        this.chasis.axles.push(axle);
      })
      this.updateChasis();

    },
    onRemoveAxle: function(index){
      this.$nextTick(() => {
        this.chasis.axles.splice(index,1)
      })
      this.updateChasis();
    },
    addSpare: function(){
      this.$nextTick(() => {
        this.chasis.spares++
       this.updateChasis();
      })
    },
    removeSpare: function(){
      this.$nextTick(() => {
        this.chasis.spares--
       this.updateChasis();
      })
    },
    updateChasis: function() {
      this.$emit("save",JSON.parse(JSON.stringify(this.chasis)));
      this.$store.commit('vehicles/saveTirePattern',JSON.parse(JSON.stringify(this.chasis)));

    },

    onLoad: function(){
      this.chasis = JSON.parse(JSON.stringify(this.$store.getters["vehicles/getTirePattern"] ));
    },

    onChange: function(obj) {


      switch (obj.wheels) {
        case 1:
          this.chasis.axles[obj.axle_index].wheel_configuration = 'single'; break;
        case 2: this.chasis.axles[obj.axle_index].wheel_configuration = 'double';break;
        case 3: this.chasis.axles[obj.axle_index].wheel_configuration = 'triple';break;
      }



      this.updateChasis();


    }

  },
  mounted() {
    //console.log(`xPos: ${this.x} and yPos: ${this.y}`)
    this.onLoad();
  }
}
</script>
<style scoped lang="stylus">

</style>