import BlankPage from "@/modules/global/layouts/BlankPage";
import DashboardPage from "@/modules/dashboard/layouts/DashboardPage";
import ContactsPage from "@/modules/contacts/layouts/ContactsPage";
import ContactPage from "@/modules/contacts/layouts/ContactPage";

import VehiclesPage from "@/modules/vehicles/layouts/VehiclesPage";
import VehiclePage from "@/modules/vehicles/layouts/VehiclePage";

import IssuesPage from "@/modules/issues/layouts/IssuesPage";
import IssuePage from "@/modules/issues/layouts/IssuePage";
import FuelsPage from "@/modules/fuel/layouts/FuelsPage";
import FuelPage from "@/modules/fuel/layouts/FuelPage";


import InventorysPage from "@/modules/inventory/layouts/InventorysPage";
import InventoryPage from  "@/modules/inventory/layouts/InventoryPage";



import RemindersPage from "@/modules/reminders/layouts/RemindersPage";
import ReminderPage from "@/modules/reminders/layouts/ReminderPage";

import ServicesPage from "@/modules/services/layouts/ServicesPage";
import ServicePage from "@/modules/services/layouts/ServicePage";

import MessagePage from "@/modules/messaging/layouts/MessagePage";
import MessegesPage from "@/modules/messaging/layouts/MessegesPage";

import WorkflowsPage from "@/modules/workflow/layouts/WorkflowsPage";
import WorkflowPage from "@/modules/workflow/layouts/WorkflowPage";
import InspectionsPage from "@/modules/inspections/layouts/InspectionsPage";
import InspectionPage from "@/modules/inspections/layouts/InspectionPage";

import PlacesPage from "@/modules/places/layouts/PlacesPage";
import PlacePage from "@/modules/places/layouts/PlacePage";

import ReportsPage from "@/modules/reports/layouts/ReportsPage";
import ReportPage from "@/modules/reports/layouts/ReportPage";
import SettingsPage from "@/modules/settings/layouts/SettingsPage";

import {SETTINGS_ROUTES} from "@/modules/settings/router/SettingsRoutes";
import {VEHICLE_ROUTES} from "@/modules/vehicles/router/vehicleRouter";
import {CONTACT_ROUTES} from "@/modules/contacts/router/contactRouter";
import {REMINDER_ROUTES} from "@/modules/reminders/routes/reminderRouter";
import {SERVICE_ROUTES} from "@/modules/services/router/serviceRoutes";
import {WORKFLOW_ROUTES} from "@/modules/workflow/router/WorkflowRouter";
import {INSPECTION_ROUTES} from "@/modules/inspections/router/inspectionRouter";

import {ISSUE_ROUTES} from "@/modules/issues/router/issueRouter";
import {FUEL_ROUTES} from "@/modules/fuel/router/fuelRouter";
import {EXPENSE_ROUTES} from "@/modules/expense/router/expenseRouter";
import {INVENTORY_ROUTES} from "@/modules/inventory/router/InventoryRouter";
import {PLACE_ROUTES} from "@/modules/places/router/PlaceRouter";
import {MAP_ROUTES} from "@/modules/maps/router/MapRouter";
import {REPORT_ROUTES} from "@/modules/reports/router/reportRouter";
import {MESSAGE_ROUTES} from "@/modules/messaging/router/messagingRoutes";
import ExpensesPage from "@/modules/expense/layouts/ExpensesPage.vue";
import ExpensePage from "@/modules/expense/layouts/ExpensePage.vue";

export const MAIN_ROUTES = [
    {
        path: "dashboard",
        name: "dashboard",
        component: DashboardPage,
    },
    {
        path:"blank",
        name: "blank",
        component: BlankPage
    },

    {
        path: "maps/:tabID?",
        name: "maps",
        component: () =>
            import(/* webpackChunkName: "maps" */ "@/modules/maps/layouts/MapsPage"),
    },
    {
        path: "map/:mapID",
        name: "map",
        component: () =>
            import(/* webpackChunkName: "maps2" */ "@/modules/maps/layouts/MapPage"),
        children: MAP_ROUTES,
    },

    {
        path: "workflows/:tabID?",
        name: "workflows",
        component: WorkflowsPage,
    },
    {
        path: "workflow/:workflowID",
        name: "workflow",
        component: WorkflowPage,
        children: WORKFLOW_ROUTES,
    },
    {
        path: "inspections/:tabID?",
        name: "inspections",
        component: InspectionsPage,
    },
    {
        path: "inspection/:inspectionID",
        name: "inspection",
        component: InspectionPage,
        children: INSPECTION_ROUTES,
    },

    {
        path: "issues",
        name: "issues",
        component: IssuesPage,
    },
    {
        path: "issue/:issueID",
        name: "issue",
        component: IssuePage,
        children: ISSUE_ROUTES,
    },

    {
        path: "fuels/:tabID?",
        name: "fuels",
        component: FuelsPage,
    },
    {
        path: "fuel/:fuelID",
        name: "fuel",
        component: ExpensePage,
        children: EXPENSE_ROUTES,
    },
    {
        path: "expenses/:tabID?",
        name: "expenses",
        component: ExpensesPage,
    },
    {
        path: "expense/:expenseID",
        name: "expense",
        component: FuelPage,
        children: FUEL_ROUTES,
    },

    {
        path: "reminders/:tabID?",
        name: "reminders",
        component: RemindersPage,
    },

    {
        path: "reminder/:reminderID",
        name: "reminder",
        component: ReminderPage,
        children: REMINDER_ROUTES,
    },

    {
        path: "services/:tabID?",
        name: "services",
        component: ServicesPage,
    },

    {
        path: "service/:serviceID",
        name: "service",
        component: ServicePage,
        children: SERVICE_ROUTES,
    },

    {
        path: "vehicles/:tabID?",
        name: "vehicles",
        component: VehiclesPage,
    },
    {
        path: "vehicle/:vehicleID",
        name: "vehicle",
        component: VehiclePage,
        children: VEHICLE_ROUTES,
    },

    {
        path: "contacts/:tabID?",
        name: "contacts",
        component: ContactsPage,
    },
    {
        path: "contact/:contactID",
        name: "contact",
        component: ContactPage,
        children: CONTACT_ROUTES,
    },

    {
        path: "inventorys/:tabID?",
        name: "inventorys",
        component: InventorysPage,
    },
    {
        path: "inventory/:itemID",
        name: "inventory",
        component: InventoryPage,
        children: INVENTORY_ROUTES,
    },
    {
        path: "places/:tabID?",
        name: "places",
        component: PlacesPage,
    },
    {
        path: "place/:placeID",
        name: "place",
        component: PlacePage,
        children: PLACE_ROUTES,
    },

    {
        path: "reports/:tabID?",
        name: "reports",
        component: ReportsPage,
    },
    {
        path: "report/:reportID?",
        name: "report",
        component: ReportPage,
        children: REPORT_ROUTES,
    },

    {
        path: "settings",
        name: "settings",
        component: SettingsPage,
        children: SETTINGS_ROUTES,
    },

    {
        path: "messages",
        name: "messages",
        component: MessegesPage,
    },

    {
        path: "message/:messageID",
        name: "message",
        component: MessagePage,
        children: MESSAGE_ROUTES,
    },
];
