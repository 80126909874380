<template lang="pug">
  .px-2()

    v-layout.px-4(justify-space-between="" )
      div()
        .title.font-weight-bold() Tire Stores
      v-btn(color="primary" small @click="addNew" ) Add New
    v-divider.my-4
    tire-store-table(:loadeing="loading")
</template>
<script>
import TireStoreTable from "@/modules/inventory/tire_management/components/tables/TireStoreTable";
export default {
  components: {
    TireStoreTable
  },
  data: () => ({
    loading: false
  }),
  mounted() {
    this.loading = false
    this.$store.dispatch("tireStore/loadAllTireStores").then(() => {
      this.loading = true;
    })
  },
  methods: {
    addNew: function() {

      this.$store
          .dispatch("tireStore/addTireStore").then(() => {
            this.$router.push({
              name: "tireStoreForm",
              params: {
                tireStoreID: this.$store.getters["tireStore/getCurrentTireStore"].id
              }
            }).catch(()=>{});
          });
    },
  }
}
</script>
<style scoped lang="stylus"></style>
