<template>
  <g>
    <rect
        :x="x - (0.5*width)"
        :y="y - (0.5*height)"
        :height="100"
        width="40"
        :fill="wheelColor"
        rx="10"
        ry="10"
        @click="onClick2"
        :transform="`rotate(${rotationAngle}, ${x}, ${y})`"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
    />
    <text
        :x="x - (0.5*width)+20"
        :y="y-8"
        class="font-weight-thin"
         fill="black" text-anchor="middle">A{{axle_index+1}}
    </text>
    <text
        :x="x - (0.5*width)+20"
        :y="y+8"
        class="font-weight-bold text-center"
        fill="black" text-anchor="middle">{{getPosition}}
    </text>
  </g>

</template>
<script>
export default {
  props: {
    axle_index: {
      type: Number,
      default: 0
    },
    wheel_index: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: null
    },
    side: {
      type: String,
      default: 'right',
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      default: 100
    },
    rotationAngle: {
      type: Number,
      default: 0
    },
    wheels:{
      type: Number,
      default: 0
    }
  },
  computed: {
    getXPos() {
      if (this.side === 'right') {
        return this.x - this.width
      }else{
        return this.x
      }
    },
    getPosition() {
      if (this.side === 'right') {
        return `R${this.getWheelPosition}`
      }else{
        return `L${this.getWheelPosition}`
      }
    },

    getWheelPosition() {
      let position = "";
      if (this.wheels == 3 ){
        if(this.wheel_index == 2) {
          position = "v"
        }
        if(this.wheel_index == 1) {
          position = "i"
        }

        if(this.wheel_index == 0) {
          position = "o"
        }

      }

      if (this.wheels == 2 && this.wheel_index == 1){
        position = "i"
      }

      if (this.wheels == 2 && this.wheel_index == 0){
        position = "o"
      }



      return position;
    }
  },
  data: () => ({
    wheelColor: '#ffCC00'
  }),
  methods:{
    onClick2: function () {
      console.log("onClick2");
    },
    onMouseOver: function() {
      this.wheelColor = '#41b883';
    },
    onMouseLeave: function() {
      this.wheelColor = '#ffCC00';
    }
  }
}
</script>

<style scoped lang="stylus">

</style>