<template lang="pug">
div
  v-divider
  v-container.mx-4(fluid)
    v-layout.mr-4.my-2(row, wrap)
      h2 Expense History
      v-spacer
      .text-xs-center
        v-menu(offset-y)
          template(#activator="{on}")
            v-btn(small, outlined, color="primary", dark, v-on="on")
              | Export
              v-icon(dark) keyboard_arrow_down
          v-list-item-group
            v-list-item(
              v-for="item in items",
              :key="item.title",
              dense,
              @click="onSelectExport(item.value)"
            )
              v-list-item-title
                v-layout(align-center)
                  v-icon.mr-2(:size="20") {{ item.icon }}
                  .subtitle-2.mx-2 {{ item.title }}
      v-btn.mx-2(small, color='primary')
        v-icon.pr-2(dark) search
        | Search
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='openNew') Add New Expense
  v-divider
  v-layout.mx-4(row, wrap, align-center)
    v-layout(align-center, style='max-width:900px;')
      v-text-field.pl-2(style='width:10px;', v-model='search',append-icon="search" label='Vehicles Search', :persistent-hint='true', required)
      v-btn.ma-2.grey--text(v-if="search.length > 0" text outlined small @click="search=''") clear all
  v-divider
  .grey.lighten-4.py-3
  v-divider
  expense-table(:search="search")
  export-data-dialog(:fileExport="fileExport" :isFinished="isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="showExportDialog = false;" @download="onDownload" )
  v-dialog(v-model="dialog" width="500")
    select-vehicles(v-if="dialog" :showDialog="true" @close="onCloseDialog"  @save="onSaveDialog")

</template>

<script>
import ExpenseTable from "@/modules/expense/components/tables/ExpenseTable";
import Repository from "@/repository";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog.vue";
import SelectVehicles from "@/modules/global/components/dialog/SelectVehicles.vue";

export default {
  components: {
    SelectVehicles,
    ExpenseTable,
    ExportDataDialog,
  },
  data: () => ({
    selectedExport:null,
    dialog:false,
    isDownloadFinished: false,
    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    action: null,
    search: "",
    items: [
      {
        title: "Export CSV",
        icon: "cloud_download",
        value: "export_csv"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }
    ],
    headers:[
      {
        text: "Name",
        value: "name",
        align: "left",
        visible: "true"
      },
      {
        text: "Description",
        value: "description",
        align: "left",
        visible: "true"
      },
      {
        text: "Vehicle",
        value: "vehicle",
        align: "left",
        visible: "true"
      },
      {
        text: "Date",
        value: "date_time",
        align: "left",
        visible: "true"
      },
      {
        text: "Reported By",
        value: "reported_by",
        align: "left",
        visible: "true"
      },
      {
        text: "Total",
        value: "total",
        align: "left",
        visible: "true"
      },
      {
        text: "Photos",
        value: "photos_id",
        align: "left",
        visible: "true"
      }
    ]
  }),
  methods: {
    openNew: function() {
      this.$store.dispatch("expenses/addExpense").then(() => {
        let newID = this.$store.state.expenses.expense_data[
          this.$store.state.expenses.currentIndex
        ].id;
        this.$router.push({
          name: "expenseForm",
          params: { vehicleID: newID }
        }).catch(()=>{});
      });
    },

    showDialog: function() {},

    closeDialog: function() {},

    tutorialAction: function() {
      console.log("tutorial Action Clicked");
    },

    onDownload: function () {
      // download the file
      this.fileLink.click();
      this.showExportDialog = false;
    },

    onSelectExport: function(value){
      this.selectedExport = value;
      this.dialog = true;
    },

    onExport: function(value,from,to,ids) {
      switch (value) {
        case "export_csv":
          this.fileExport = "CSV";
          this.isDownloadFinished = this.downloadFile(
              `/report/CSV/expense_list?&from=${from}&to=${to}${ids}`
          );
          break;
        case "export_excel":
          this.fileExport = "Excel";
          this.isDownloadFinished = this.downloadFile(
              `/report/Excel/expense_list?&from=${from}&to=${to}${ids}`
          );
          break;
      }
      this.showExportDialog = !this.showExportDialog;
    },

    downloadFile: function (url) {
      this.isDownloadFinished = false;

      Repository({
        method: "POST",
        url: url,
        data: this.headers,
        responseType: "blob",
      }).then((response) => {

        var fileName = response.headers["file"];

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        this.fileLink = document.createElement("a");

        this.fileLink.href = fileURL;
        this.fileLink.setAttribute("download", fileName);
        document.body.appendChild(this.fileLink);

        this.isDownloadFinished = true;
      });
    },


    onCloseDialog: function(){
      this.dialog = false;
      this.isLoading = false;
    },

    onSaveDialog: function(result){
      this.dialog = false;
      var ids = "";

      result.selectedVehicles.forEach(vehicle => {
        ids += "&id="+vehicle.id
      })

      this.onExport(this.selectedExport,result.from,result.to,ids)

    },

  },


  mounted() {
    this.$store.dispatch("expenses/loadAllExpenses");
  }
};
</script>

<style scoped></style>
