<template lang="pug">
  div()
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small outlined color="primary" @click="save") save and assign to vehicle
      v-btn.ma-2(small color="primary" @click="save") save

    v-form(ref="form" v-model="valid" lazy-validation)
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")

      v-container
        v-card
          v-card-text
            v-container(grid-list-xl)
              form-divider Tire Model Details
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  tire-model-auto-complete(v-model="tire_model_id" :rules="[rules.required]" :clearable="true" @change="onChangeTire")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field.mt-3(v-model='serial', hint='Unique Serial Number of Tire', :rules="[rules.required]" persistent-hint, label='Serial Number')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field.mt-3(v-model='dot_id', hint='Dot Code', persistent-hint, label='Dot Code')
              form-divider Tire Sepcs
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  date-time-picker(v-model="purchase_date", labelDate="Purchase Date", labelTime="Purchase Time")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='purchase_tread_depth', hint='Purchase Tread Depth', :rules="[rules.required]" persistent-hint, label='Purchase tread Depth')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='purchase_cost', hint='Purchase Cost', :rules="[rules.required]" persistent-hint, label='Purchase Cost')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='purchase_warranty', hint='Purchase Warranty', :rules="[rules.required]" persistent-hint, label='Purchase Warranty')

              .grey.rounded-xl.py-2.lighten-3.my-4
                v-row.mx-2()
                  v-col.px-2(cols="12" xs="12" md="6" xl="4")
                    tire-store-auto-complete(v-model="tire_store_id" :clearable="true")
                  v-col.px-2.mt-2(cols="12" xs="12" md="6" xl="4")
                    .d-flex.align-end
                      v-text-field.mr-4(v-model='aisle' label='Aisle')
                      v-text-field.mr-4(v-model='row' label='Row')
                      v-text-field(v-model='bin' label='Bin')

              form-divider Others
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-select(v-model='status', :items='tireStatusOptions', label='Tire Status' persistent-hint hint="Tire Status")

                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='description' hint='Description', persistent-hint, label='Description')
                v-col(cols="12"  xs="12")
                  attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")



</template>
<script>
import {createHelpers} from "vuex-map-fields";
import TireModelAutoComplete   from "@/modules/global/components/forms/TireModelAutoComplete";
import TireStoreAutoComplete from "@/modules/global/components/forms/TireStoreAutoComplete";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import DateTimePicker from "@/modules/global/components/forms/DateTimePicker";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";


const wholeNumberPattern = /^\d+$/;
const tireRegex = /\d{3}\/\d{2}[Rr]\d{2}/;
const numberRegex = /^-?\d*\.?\d+$/;

const {mapFields} = createHelpers({
  getterType: "tire/getTire",
  mutationType: "tire/updateTire",
});
export default {
  components:{
    TireModelAutoComplete,
    TireStoreAutoComplete,
    FormDivider,
    DateTimePicker,
    AttachFillForm
  },
  data: () => ({
    aisle:null,
    row:null,
    bin:null,
    error: false,
    errors: [],
    valid: true,
    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true,
    },
    tireStatusOptions: [ 'new' ,'in_service','pending_retread','at_retreader' ,'retreaded', 'scrapped'],
    additionalImageMenuItems: [
    ],
    rules: {
      required: (value) => !!value || "Required.",
      isWholeNumber: (value) => (value == null || value === "") ? true : wholeNumberPattern.test(value) || "Must be a whole number",
      isTireNumber: (value) => tireRegex.test(value) || "Must have format ###/##R## e.g. 225/50R17",
      isNumber: (value) => (value == null || value === "") ? true :  numberRegex.test(value) || "Must be a number",
    },
  }),
  computed: {
    ...mapFields([
      "tire_model_id",
        "serial_number",
      "dot_id",
      "description",

        "purchase_date",
        "purchase_tread_depth",
        "purchase_cost",
        "purchase_warranty",

        "tire_store_id",
        "status",
        "photos_id"
    ])
  },

  methods: {

    onChangeTire: function (value) {
      this.$store.commit("tire/setTireDefaultsByModel", this.$store.getters["tireModel/getTireModelById"](value));
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Tire Management",
        disabled: false,
        to: {name: "inventorys", params: {tabID: 1}}
      });
      theArray.push({
        text: "Tire",
        disabled: true
      });
      return theArray;
    },

    cancel: function() {
      this.$store.dispatch("tire/loadAllTires").then(()=>{
        this.$router.back();
      })
    },

    save:function(){
      if (this.$refs.form.validate()){
        this.$store.dispatch("tire/saveTire").then(()=>{
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Tire "${this.name}" is saved`
          });

          this.$store.dispatch("tire/loadAllTires");
          this.$router.push({name: "inventorys", params: {tabID: 1}}).catch(()=>{});

        })
      }
    },
    saveImages: function (theObject) {
      this.$store.commit("tire/updateTire", {
        path: "photos_id",
        value: theObject.value,
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("tire/updateTire", {
        path: "documents_id",
        value: theObject.value,
      });
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("tire/deleteImage", value.theObject.id);
          break;
      }
    },
  },
}
</script>



<style scoped lang="stylus">

</style>