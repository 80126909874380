import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const TIRESTORE_API = "/tireStore";

// ----------------------- STATE ------------------------- //
const state = {
    tireStore_data: [],
    currentIndex: -1,
    selectedTireStores: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getTireStore: state => getField(state.tireStore_data[state.currentIndex]),

    getCurrentTireStore: state => {
        return state.tireStore_data[state.currentIndex];
    },

    getSelectedTireStores: state => {
        return state.selectedTireStores;
    },

    getSelectedTireStoreById: state => tireStoreID => {
        return state.tireStore_data.find(item => item.id == tireStoreID);
    }


};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateTireStore(state, payload) {
        updateField(state.tireStore_data[state.currentIndex], payload);
    },




    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.tireStore_data.findIndex(tireStore => tireStore.id == theID);
    },

    loadAllTireStores(state, payload) {
        state.tireStore_data = payload.data;
        state.currentIndex = 0;
    },




    addTireStore(state, payload) {
        state.tireStore_data.push(payload.data);
        state.currentIndex = state.tireStore_data.length - 1;
    },

    saveTireStore(state, payload) {
        state.tireStore_data[state.currentIndex] = payload.data;
    },

    fetchTireStore(state, payload) {
        if (state.currentIndex == -1) {
            state.tireStore_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.tireStore_data[state.currentIndex] = payload.data;
        }
    },

    deleteTireStore(state, payload) {
        state.currentIndex = state.tireStore_data.findIndex(
            tireStore => tireStore.id == payload.data.id
        );
        state.tireStore_data.splice(state.currentIndex, 1);
    },



    reset(state) {
        state.tireStore_data = []
        state.currentIndex = -1
    },

    setSelectedTireStores(state, payload) {
        state.selectedTireStores = payload;
    },

    deleteSelectedTireStores(state, payload) {
        payload.data.forEach(element => {
            let theIndex = state.tireStore_data.findIndex(
                (tireStore) => tireStore.id == element
            );
            state.tireStore_data.splice(theIndex, 1);
        })
    },

    deleteImage(state, payload) {
        let index = state.tireStore_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );

        state.tireStore_data[state.currentIndex].photos_id.splice(index, 1);
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllTireStores({commit}) {
        try {
            commit("loadAllTireStores", await Repository.get(`${TIRESTORE_API}`));
        } catch (error) {
            console.log(error);
        }
    },



    async addTireStore({commit}) {
        await Repository.get(`${TIRESTORE_API}/new`)
            .then(response => {
                commit("addTireStore", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveTireStore({state, commit}) {
        try {
            commit(
                "saveTireStore",
                await Repository.post(
                    `${TIRESTORE_API}`,
                    state.tireStore_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchTireStore({commit}, payloadID) {
        try {
            commit("fetchTireStore", await Repository.get(`${TIRESTORE_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteTireStore({commit}, payloadID) {
        try {
            commit(
                "deleteTireStore",
                await Repository.delete(`${TIRESTORE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },




    async deleteSelectedTireStores({commit}, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedTireStores",
                await Repository.delete(`${TIRESTORE_API}/deleteSelected?${idString}`)
            );
        } catch (error) {
            console.log(error);
        }

    },

    async searchTireStores({commit}, searchTerm) {
        try {
            commit(
                "loadAllTireStores",
                await Repository.get(`${TIRESTORE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
