<template lang="pug">
div(v-if="tireStore" )

  .ml-0(v-if="tireStore != null" @click="openTireStoreDetail(tireStore.id)")
    .d-flex.align-center.tyreComponent.py-1()
      image-thumbnail.mr-3(v-for="(itemID,index) in tireStore.photos_id" :key="index" :width='60', :height='60', :src="itemID")
      .d-flex.flex-column()
        .font-weight-bold() {{tireStore.name}}
        .caption.grey--text() {{tireStore.city}}

</template>
<script>
import axios from "axios";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail,
  },
  props: {
    tireStore_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    tireStore: null
  }),
  watch:{
    tireStore_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getTireStore();
      }
    }
  },
  methods: {
    getTireStore: function(){
      let tempTireStore = this.$store.getters["tireStore/getSelectedTireStoreById"](this.tireStore_id);
      console.log(tempTireStore);
      if (tempTireStore == undefined) {
        axios.get(`/api/tireStore/${this.tireStore_id}`).then((response)=>{
          this.tireStore = response.data;
          console.log(this.tireStore);
        });
      } else {
        this.tireStore = tempTireStore;
      }
    },
    openTireStoreDetail: function (tireStoreID) {
      if(this.clickToDetail){
        this.$store.commit("tireStore/setCurrentIndexByID", tireStoreID);
        // this.$router.push({
        //   name: "tireStoreDashboard",
        //   params: {contactID: tireStoreID}
        // }).catch(()=>{});
      }

    }
  },
  mounted() {
    this.getTireStore();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
