import ItemDetailsForm from "@/modules/inventory/items/components/forms/ItemDetailsForm";
import PurchaseOrderDetailsForm from "@/modules/inventory/purchase_orders/forms/PurchaseOrderDetailsForm";
import InventoryOverviewLayout from "@/modules/inventory/layouts/InventoryOverviewLayout";
import InventoryForm from "@/modules/inventory/components/forms/InventoryForm";
import TireModelForm from "@/modules/inventory/tire_management/components/forms/TireModelForm";
import TireStoreForm from "@/modules/inventory/tire_management/components/forms/TireStoreForm";
import TireForm from "@/modules/inventory/tire_management/components/forms/TireForm";
import TirePatternLayout from "@/modules/inventory/tire_management/layouts/TirePatternLayout";


export const INVENTORY_ROUTES = [
    {
        path: "itemDetailsForm",
        name: "itemDetailsForm",
        component: ItemDetailsForm
    },
    {
        path: "inventoryOverviewLayout/:tabID?",
        name: "inventoryOverviewLayout",
        component: InventoryOverviewLayout
    },

    {
        path: "purchaseOrderDetailsForm",
        name: "purchaseOrderDetailsForm",
        component: PurchaseOrderDetailsForm
    },
    {
        path: "inventoryForm",
        name: "inventoryForm",
        component: InventoryForm
    },
    {
        path: "tireStoreForm",
        name: "tireStoreForm",
        component: TireStoreForm
    },
    {
        path: "TireModelForm",
        name: "TireModelForm",
        component: TireModelForm
    },
    {
        path: "tireForm",
        name: "tireForm",
        component: TireForm
    },
    {
        path: "tirePatternLayout",
        name: "tirePatternLayout",
        component: TirePatternLayout

    }
];
