<template lang="pug">
  div
    v-autocomplete(
      v-model="tireModels"
      :items="items == null ? $store.state.tireModel.tireModel_data: items"
      :loading="isLoading"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      :label="label"
      :placeholder= "placeholder"
      chips
      @change="onChange"
      :filter="onFilter"
      :clearable="clearable"
      :disabled="disabled"
      :rules="rules"
    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:input-value="data.selected")
          .d-flex.align-center
            image-thumbnail.ml-n2.mr-2(v-if="data.item.photos_id && data.item.photos_id.length > 0" :width='30', :height='30', :radius='15' :src="data.item.photos_id[0]")
            .d-flex
              .font-weight-bold {{data.item.name}} &mdash; &nbsp;
              .primary--text.font-weight-bold {{data.item.size}}

      template(slot="item" slot-scope="data")
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text="data.item")
        template(v-else)
          v-list-item-content
            .d-flex.border_bottom()

              image-thumbnail.mr-3(v-if="data.item.photos_id && data.item.photos_id.length > 0" :width="60" :height="60"  :src="data.item.photos_id[0]")
              v-layout(align-center )
                .d-flex.flex-column
                  .body-2.font-weight-bold {{data.item.name}}
                  .primary--text.font-weight-bold {{data.item.size}}
                  .caption {{data.item.pattern}} | {{data.item.load_index}}{{data.item.speed_rating}}
                v-spacer()
                .d-flex.flex-column.align-end.mr-2
                  .body-2.font-weight-bold(v-if="data.item.merchant") {{data.item.merchant.name}}
                  .caption {{$store.state.profile.userInfo.account.region_settings.currency}} {{data.item.cost}}  |  {{data.item.purchase_tread_depth}}mm
</template>
<script>
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";

export default {
  components: {
    ImageThumbnail
  },

  props: {
    value: String,
    label: {
      type: String,
      default: "Select Tire Model"
    },
    placeholder: {
      type: String,
      default: "Start typing a TireModel"
    },
    items: Array,
    clearable: {
      type: Boolean,
      default: false,
    },

    disabled:{
      type:Boolean,
      default:false,
    },
    rules: Array,

  },

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    tireModels: null,
    search: null
  }),

  methods: {
    setData: function (theData) {
      this.$emit("input", theData);
    },

    onChange: function (theValue) {
      this.$emit("input", theValue);
      this.$emit("change", theValue);
    },

    onFilter: function (item, queryText) {
      const hasValue = val => (val != null ? val : "");
      const query = hasValue(queryText);
      const toSearchText =
          item.name +
          " " +
          item.size +
          " " +
          item.brand_id +
          " " +
          item.width +
          " " +
          item.merchant.name;
      return (
          toSearchText
              .toString()
              .toLowerCase()
              .indexOf(query.toString().toLowerCase()) > -1
      );
    }
  },

  mounted() {
    this.vehicles = this.value;
    // if (this.$store.state.vehicles.length < 2) {
    this.isLoading = true;
    this.$store.dispatch("tireModel/loadAllTireModels").then(() => {
      this.isLoading = false;
    });
  }
};
</script>
<style lang="stylus" scoped>
.imageCrop {
  width: 60px;
  height: 40px;
  color: red;
  border-radius: 5px;
  background-color: #CCCCCC;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emptyThumbnail {
  width: 60px;
  height: 40px;
  border: 2px dashed #CCCCCC;
  border-radius: 5px;
}

  .border_bottom{
    padding-bottom: 10px;
    border-bottom : 1px solid #CCCCCC;

  }
</style>
