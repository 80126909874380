<template lang="pug">
  div()
    div() {{$store.state.profile.userInfo.custom_dashboards}}
    template(v-if="$store.getters['profile/getCustomDashboards']" )
      v-tabs(v-model='tab' )
        v-tab() Dashboard
        v-tab(v-for='(item,index) in $store.getters[\'profile/getCustomDashboards\']' :key='index')
          | {{ item.name }}
          v-btn(icon @click="refreshIframe(index)")
            v-icon() refresh
      v-tabs-items(v-model='tab')
        v-tab-item(touchless)
          dashboard()
        v-tab-item(v-for='(item,index) in $store.getters[\'profile/getCustomDashboards\']' :key='index')
          i-frame-layout(:item="item", :index="index")
    template(v-else)
      dashboard()



  
</template>

<script>
import Dashboard from "@/modules/dashboard/layouts/Dashboard";
import Repository from "@/repository";
import IFrameLayout from "@/modules/dashboard/layouts/IFrameLayout.vue";
export default {
  components:{
    Dashboard,
    IFrameLayout
  },



  data: ()=>({
    theIndex:null,
    tab: null,
    items: [
      'web', 'shopping', 'videos', 'images', 'news',
    ],
    text:"the quick bron fox",
    flowStyle: {
      width: "100%",
      height: window.innerHeight - 90 + "px",
      "margin-top": "5px",
      "background-color":"#efefef"
    }
  }),

  methods:{
    refreshIframe : function(index){
      // console.log(document.getElementById(`${id}_frame`));
      this.theIndex = index
      let url = this.$store.getters['profile/getCustomDashboards'][index].url
      this.parseURL(url)

      // console.log(this.$store.getters['profile/getCustomDashboards'][index].url)
      // let newURL = this.$store.getters['profile/getCustomDashboards'][index].url
      // let newURL2 = newURL.replace("{{token}}",this.$store.state.profile.token)+`&rand=${Math.random()}}`
      // console.log(newURL2)
      // let newURL =  this.parseURL(this.$store.getters["profile/getCustomDashboard"][index].url)+`&rand=${Math.random()}}`;


    },

    parseURL:async function(itemURL){
      this.theURL = itemURL

      const regex = /grafana_token/g;
      const found = itemURL.match(regex);

      if (found != null && found.length > 0){
        await Repository.get("/account/getGrafanaToken").then((response)=>{
          this.theURL =  itemURL.replace("{{grafana_token}}",response.data.token)
          document.getElementById(`${this.theIndex}_frame`).src =this.theURL;
          return this.theURL
        })
      }

      document.getElementById(`${this.theIndex}_frame`).src =this.theURL;
      return this.theURL;
    },


  },

  mounted() {

  }
};
</script >

<style scoped lang="stylus">


</style>
