<template lang="pug">
  .grey(:style="getPatternStyle()")

    axle-pattern-creator(:width="width" :height="height" :x="x" :y="y" :trackWidth="trackWidth" @save="saveChasis"  )

</template>
<script>
import AxlePatternCreator from "@/modules/inventory/tire_management/components/ui/AxlePatternCreator.vue";

export default {
  components:{
    AxlePatternCreator,
  },
  data: () => ({
    width : 500,
    height : 900,
    x: 125,
    y: 80,
    trackWidth: 200,
  }),

  methods:{
    saveChasis:function(theChasis){
      console.log(`Ehello ${theChasis}`);
    },
    getPatternStyle: function(){
      let theStyle = {
        'border': '1px solid #CCCCCC',
        'border-radius': '10px',
      };
      theStyle.width = `${this.width}px`;
      theStyle.height = `${this.height}px`;

      return theStyle;
    },


  }
}
</script>



<style scoped lang="stylus">

</style>