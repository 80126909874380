<template lang="pug">
  div
    // Tabs Selector
    .white.pt-2
      v-tabs.tabs(v-model='active' height='32' left='' slider-color='primary')
        v-tab.tabs Items List
        v-tab-item
          items-list-layout
        v-tab.tabs() Tire List
        v-tab-item
          tire-layout
        v-tab.tabs() Tire Models
        v-tab-item
          tire-model-layout
        //-- v-tab.tabs Purchase Orders
          v-tab-item
            purchase-order-layout
</template>

<script>
import ItemsListLayout from "@/modules/inventory/items/layouts/ItemsListLayout";
import PurchaseOrderLayout from "@/modules/inventory/purchase_orders/layouts/PurchaseOrderLayout";
import TireLayout from "@/modules/inventory/tire_management/layouts/TireLayout.vue";
import TireModelLayout from "@/modules/inventory/tire_management/layouts/TireModelLayout.vue";
export default {
  components: {
    ItemsListLayout,
    PurchaseOrderLayout,
    TireModelLayout,
    TireLayout
  },

  computed: {
    active: {
      get: function () {
        let tabIndex = 0;
        if (this.$route.params.tabID != undefined) {
          tabIndex = parseInt(this.$route.params.tabID);
          // tabIndex = tabIndex > 2 || tabIndex < 0 ? 0 : tabIndex;
        }
        return tabIndex;
      },

      set: function (value) {
        this.$route.params.tabID = value;
      }
    }
  },

  mounted() {
    let max = (this.$store.state.profile.userInfo.is_administrator) ? 3 : 2;
    if (this.$route.params.tabID != undefined) {
      let tabIndex = parseInt(this.$route.params.tabID);
      this.active = tabIndex > max || tabIndex < 0 ? 0 : tabIndex;
    } else {
      this.active = 0;
    }
  }
};
</script>

<style scoped></style>
