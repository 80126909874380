<template lang="pug">
   v-data-table(
   :headers="headers",
   :items="$store.state.tireStore.tireStore_data",
   item-key="id"
   :loading="loading",
   :single-select="false"
   show-select
   v-model="selectedTireStores"
   )
      v-progress-linear(slot='progress', height='2', :indeterminate='loading')
      template( v-slot:item.manager_id="{ item }")
        contact-column-by-id.py-1(v-if="item.manager_id" :contact_id="item.manager_id")
      template(v-slot:item.city="{ item }")
        .d-flex()
          image-thumbnail.mr-3(v-for="(itemID,index) in item.photos_id" :key="index" :width='80', :height='60', :src="itemID")
          div()
            a.caption(v-if="item.location.lon !== 0" :href="`https://maps.google.com/?q=${item.location.lat},${item.location.lon}`" target="_blank") view on map location
            div() {{item.city}},{{item.region}} <br/>{{item.country}}

      template( v-slot:item.name="{ item }")
        .d-flx()
          .font-weight-bold() {{item.name}}
      template( v-slot:item.action="{ item }")
        v-row.mr-7(justify="end")
          more-drop-down-menu(:items='menuitems', @onClick='onClick', :theObject='item' )

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById";
import IconChip from "@/modules/global/components/table/IconChip";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail.vue";
export default {
  props: ["loading"],
  components: {
    MoreDropDownMenu,
    ContactColumnById,
    IconChip,
    ImageThumbnail
  },

  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
        align: "left"
      },
      {
        text: "Location",
        value: "city",
        align: "left"
      },
      {
        text: "Quantity",
        value: "quantity",
        align: "center"
      },
      {
        text: "Max Quantity",
        value: "max_quantity",
        align: "center"
      },
      {
        text: "Manager",
        value: "manager_id",
        align: "left"
      },
      {text: "Action", value: "action", tooltip: "Edit", align: "right"}
    ],
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"}
    ]
  }),
  computed:{
    selectedTireStores: {
      get: function () {
        return this.$store.getters["tireStore/getSelectedTireStores"];
      },
      set: function (value) {
        this.$store.commit("tireStore/setSelectedTireStores", value);
      }
    },
  },
  methods: {
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("tireStore/setCurrentIndexByID", value.theObject.id);
          this.$router.push({
            name: "tireStoreForm",
            params: {tireStoreID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("tireStore/deleteTireStore", value.theObject.id);
          break;
      }
    }
  }
}
</script>
<style scoped lang="stylus">

</style>