<template lang="pug">
  div()
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") save

    v-form(ref="form" v-model="valid" lazy-validation)
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")

      v-container
        v-card
          v-card-text
            v-container(grid-list-xl)
              form-divider Tire Model Details
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='name', hint='Name of Tire Model', :rules="[rules.required]" persistent-hint, label='Name')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='size',:rules="[rules.isTireNumber]" hint='Size of Tire format: ##/##R##', persistent-hint, label='Size')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='brand_id', hint='Brand ID' :rules="[rules.required]" persistent-hint, label='Brand ID')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='pattern', hint='Tyre Pattern', :rules="[rules.required]",  persistent-hint, label='Pattern')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model.number='load_index', :rules="[rules.isWholeNumber,rules.required]" hint='Load Index Rating', persistent-hint, label='Load Index Rating')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='speed_rating', hint='F<50, G<56, J<62, K<68, L<75, M<81', persistent-hint, label='Speed Rating')
              form-divider New Tire Defaults
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  merchant-auto-complete(v-model="merchant_id")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model.number='cost' :rules="[rules.isNumber]"  hint='Cost', persistent-hint, label='Cost')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model.number='purchase_tread_depth' :rules="[rules.isNumber]"  hint='Tread Depth on Purchase', persistent-hint, label='Tread Depth')
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model.number='warranty_period', :rules="[rules.isWholeNumber]", hint='Warranty Period in Days', persistent-hint, label='Warranty Period')
              form-divider Attachments
              v-row()
                v-col(cols="12"  xs="12")
                  attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")


</template>
<script>
import {createHelpers} from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import MerchantAutoComplete from "@/modules/global/components/forms/MerchantAutoComplete.vue";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

const {mapFields} = createHelpers({
  getterType: "tireModel/getTireModel",
  mutationType: "tireModel/updateTireModel",
});

const wholeNumberPattern = /^\d+$/;
const tireRegex = /\d{3}\/\d{2}[Rr]\d{2}/;
const numberRegex = /^-?\d*\.?\d+$/;


export default {
  components: {
    FormDivider,
    MerchantAutoComplete,
    AttachFillForm
  },
  data: () => ({
    error: false,
    errors: [],
    valid: true,
    sample: null,

    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true,
    },
    additionalImageMenuItems: [
    ],
    rules: {
      required: (value) => !!value || "Required.",
      isWholeNumber: (value) => (value == null || value === "") ? true : wholeNumberPattern.test(value) || "Must be a whole number",
      isTireNumber: (value) => tireRegex.test(value) || "Must have format ###/##R## e.g. 225/50R17",
      isNumber: (value) => (value == null || value === "") ? true :  numberRegex.test(value) || "Must be a number",
    },



  }),
  computed: {
    ...mapFields([
        "name",
        "brand_id",
        "pattern",
        "size",
        "load_index",
        "speed_rating",
        "recommended_retreads",
        "warranty_period",
        "cost",
        "purchase_tread_depth",
        "photos_id",
        "merchant_id",
    ])
  },

  methods:{
    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Tire Management",
        disabled: false,
        to: {name: "inventorys", params: {tabID: 2}}
      });
      theArray.push({
        text: "Tire Model Entry",
        disabled: true
      });
      return theArray;
    },
    cancel: function() {
      this.$store.dispatch("tireModel/loadAllTireModels").then(()=>{
        this.$router.back();
      })
    },
    save: function() {
      if (this.$refs.form.validate()){
        this.$store.dispatch("tireModel/saveTireModel").then(()=>{
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Store "${this.name}" is saved`
          });

          // refresh the List
          this.$store.dispatch("tireModel/loadAllTireModels");
          this.$router.push({name: "inventorys", params: {tabID: 2}}).catch(()=>{});
        })
      }



    },

    saveImages: function (theObject) {
      this.$store.commit("tireModel/updateTireModel", {
        path: "photos_id",
        value: theObject.value,
      });
    },

    saveDocuments: function (theObject) {
      this.$store.commit("tireModel/updateTireModel", {
        path: "documents_id",
        value: theObject.value,
      });
    },

    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("tireModel/deleteImage", value.theObject.id);
          break;
      }
    },
  }
}
</script>



<style scoped lang="stylus">

</style>