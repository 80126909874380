<template>

  <g>
    <polyline v-if="axleType==='steer'" :points="getSteeringArray()" fill="none"  stroke="#666666"  stroke-width="8" />
    <circle
        v-if="axleType==='drive'"
        :cx="x+(trackWidth*0.5)"
        :cy="y"
        r="30"
        fill="#444444"
      />
    <g :transform="`translate(${trackWidth*0.5}, 60)`"  class="addAxle" @click="$emit('addAxle')">
      <line :x1="x" :y1="y-50" :x2="x" :y2="y" stroke="black" stroke-width="4"  stroke-dasharray="4,4"  />
      <circle
          :cx="x"
          :cy="y"
          r="15"
          fill="#FFFFFF"
          stroke="#CCCCCC"
          stroke-width="2"
      />
      <line
          :x1="x" :y1="y-7" :x2="x" :y2="y+7" stroke="black" stroke-width="4"
      />
      <line
          :x1="x-7" :y1="y" :x2="x+7" :y2="y" stroke="black" stroke-width="4"
      />
    </g>


    <rect
        fill="#333333"
        :width="trackWidth+(offset*2)"
        :height="axleThickness"
        :x="x-offset"
        :y="y-5"
    />
    <g  :transform="`translate(${trackWidth*0.5}, 0)`" @click="$emit('removeAxle',index)" >
      <circle
          :cx="x"
          :cy="y"
          r="20"
          fill="#FFFFFF"
          stroke="#e74c3c"
          stroke-width="4"
      />
      <line
          :x1="x-9" :y1="y" :x2="x+9" :y2="y" stroke="#e74c3c" stroke-width="6"
      />
      <rect
          fill="#000000"
          width="15"
          height="40"
          :x="x+(-trackWidth*0.5-15)-offset"
          :y="y-20"
        />
      <rect
          fill="#000000"
          width="15"
          height="40"
          :x="x+(trackWidth*0.5)+offset"
          :y="y-20"

      />
    </g>

        <wheel v-for="(item,wheelIndex) in wheels" :key="'leftWheel_'+wheelIndex" side="left" :x="x+(wheelIndex*50)-30-offset" :y="y" :width="40" :height="100" :rotation-angle="tireRotation" :axle_index="index" :wheel_index="wheelIndex" :wheels="wheels"  />
        <wheel v-for="(item,wheelIndex) in wheels" :key="'rightWheel'+wheelIndex" side="right" :x="trackWidth + x-(wheelIndex*50)+30+offset" :y="y" :rotation-angle="tireRotation"  :axle_index="index"  :wheel_index="wheelIndex"  :wheels="wheels"  />

    <g  :transform="`translate(${30-offset-(trackWidth*0.5)}, 0)`" >
      <g  @click="addWheel">
        <circle
            :cx="x"
            :cy="y"
            r="12"
            fill="#FFFFFF"
            stroke="#CCCCCC"
            stroke-width="1"
        />
        <line :x1="x-6" :x2="x+6" :y1="y" :y2="y" fill="none" stroke="black" stroke-width="4"></line>
        <line :x1="x" :x2="x" :y1="y-6" :y2="y+6" fill="none" stroke="black" stroke-width="4"></line>
      </g>

      <g @click="removeWheel">
        <circle
            :cx="x"
            :cy="y+30"
            r="8"
            fill="#E3E3E3"
            stroke="#CCCCCC"
            stroke-width="1"

        />
        <line :x1="x-4" :x2="x+4" :y1="y+30" :y2="y+30" fill="none" stroke="orange" stroke-width="4"></line>
      </g>

    </g>

  <!--
    <text :x="x+(trackWidth*0.5)+30" class="primary--text" :y="y-15" font-size="16" fill="black" text-anchor="start">
      Axle<br/> {{index+1}}
    </text>
-->


  </g>
</template>
<script>
import Wheel from "@/modules/inventory/tire_management/components/ui/AxleComponents/Wheel.vue";


export default {
  props: {
    id:{
      type:Number,
      default:1,
    },
    trackWidth: {
      type: Number,
      default: 200
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    tireRotation: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    axleType: {
      type: String,
      default: 'drive',
    },
    wheel_configuration: {
      type: String,
    }

  },
  components: {Wheel},
  data: () => ({
    betweenWheelsOffset: 10,
    wheels: 0,
    rightWheels: 0,


  }),
  computed: {
    axle_index:{
      get:function(){
        return this.index;
      }
    },
    offset: {
      get: function () {
        if (this.axleType !== 'steer') {
          return 30;
        } else {
          return 0;
        }

      }
    },

  axleThickness: {
    get: function () {
      if (this.axleType === 'drive') {
        return 20
      } else {
        return 10
      }
    }
  }

  },

  mounted() {
    if(this.wheel_configuration) {
      switch (this.wheel_configuration){
        case "single": this.wheels=1; break;
        case "double": this.wheels=2; break;
        case "triple": this.wheels=3; break;
      }
    }
  },

  methods:{

    getSteeringArray: function() {
      return [this.x,this.y,this.x+40,this.y+30 ,this.x+this.trackWidth-40,this.y+30, this.x +this.trackWidth, this.y]
    },
    addWheel: function(){
      this.$nextTick(() => {
        if (this.wheels <=2){
          this.wheels++;
          this.$emit('change',{wheels:this.wheels,axle_index:this.axle_index});
        }
      })


    },
    removeWheel:function(){
      this.$nextTick(() => {
        if (this.wheels > 0) {
          this.wheels--;
        }
        this.$emit('change',{wheels:this.wheels,axle_index:this.axle_index});
      })

    },

  }

}
</script>

<style scoped lang="stylus">
.addAxle{
  cursor: pointer;
}
</style>