<template lang="pug">
  div
    v-divider
    v-container(fluid)
      v-layout.my-2(row, wrap)
        div()
          h2.mx-6 Vehicle Alerts
          generic-bread-crumbs.py-1(:items="breadCrumbsItems")
        v-spacer
        .text-xs-center
          v-btn(outlined small color="primary"  @click="onRefresh")
            v-icon() sync
          v-menu(:close-on-content-click="false")
            template(v-slot:activator="{on}")
              v-btn.ml-2(outlined color="primary" v-on="on" small)
                | Columns
                v-icon(dark) keyboard_arrow_down
            .reportHeaderList()
              v-list-item-group()
                v-list(dense)
                  v-list-item(v-for="(item, index) in headers" :key="index" )
                    template(v-slot:default="{active}")
                      v-list-item-action()
                        v-checkbox(v-model="item.visible" color="primary")
                      v-list-item-content(@click="item.visible=!item.visible")
                        v-list-item-title.body-2() {{item.text}}
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Export
                v-icon(dark) keyboard_arrow_down
            v-list-item-group()
              v-list-item(v-for='item in items', :key='item.title' dense @click="onExport(item.value)")
                v-list-item-title
                  v-layout(align-center)
                    v-icon.mr-2(:size="20") {{item.icon}}
                    .subtitle-2.mx-2() {{ item.title }}
        //- v-btn.ma-2.mr-5(small, color='primary', dark, @click='print')
          v-icon.mr-2() print
          | Print
    v-divider
    .d-flex.flex-row.align-center.mx-4.my-2()
      violation-bar-chart(v-if="chartData.violationBarChart.weekly" :theData="chartData.violationBarChart")
      violation-vehicle-pie-chart(v-if="violationVehiclePieChart" :theData="violationVehiclePieChart" :key="vehicleStatKey")
      violation-type-bar-chart(v-if="violationTypeBarChart" :theData="violationTypeBarChart" :key="violationStatTypeKey" )
      v-spacer()
      vehicle-auto-complete(v-model="filterVehicleID" :clearable="true" label="Filter By Vehicle")
      //- vehicle-filter-dialog()
      v-btn.ml-4(style="text-transform: none !important;" color="accent"  @click="onOpenDialog" v-html="startDate + endDate")


    v-divider
    .grey.lighten-4.py-3
    v-divider
    v-progress-linear(slot="progress"  height="2" :indeterminate="loading")
    div(v-if="results.length>0")
      v-data-table.elevation-1(:headers='getHeaders', :items='results',item-key="id" @click:row="onShowMap" )
        template(v-slot:progress)

        template(v-slot:item.account_id="{item}")
          img(:src="getIcon(item)" width="50" height="50")
        template(v-slot:item.vehicle_id="{item}")
          vehicle-column-by-id(:vehicle_id="item.vehicle_id")
        template(v-slot:item.street="{item}")
            .placeClick()
              template(v-if="item.street && item.street[0] == '%'")
                div.font-weight-bold()
                  v-icon() place
                  .pl-2() {{item.street.substring(1)}}
              template(v-else)
                .d-flex.flex-row()
                  v-icon() place
                  .pl-2() {{item.street}}
        template(v-slot:item.from_time="{item}")
          div(v-html="getDate(item.from_time)")



    export-data-dialog(:fileExport="fileExport" :isFinished="$store.state.reports.isDownloadFinished" v-bind:show="showExportDialog" @closeExportDialog="onCloseExportDialog" @download="onDownload" )
    v-dialog(v-model="showDialog" width="500" height="500" persistent )
      v-card()

        .d-flex.justify-space-between.primary.pa-2()
          .d-flex.flex-column.white--text
            .caption() Select Date or Date Range
            .title(v-html="startDate + endDate")


        v-divider()
        vue-ctk-date-time-picker(
          v-model="thedate"
          inline
          :no-header="true"
          :right="false"
          :range="true"
          :button-color="$vuetify.theme.themes.light.primary"
          :color="$vuetify.theme.themes.light.primary"
          :custom-shortcuts="shortcuts"
        )
        v-divider()

        v-layout(justify-end )
          v-btn.mx-2.my-4.grey--text(@click="onCancelDialog"  outlined small) Cancel
          v-btn.mx-2.my-4(@click="onUpdateDate" color="primary" small) Update
    map-dialog.mapDialog(v-if="coordinates"  @closeMap="onCloseMap" :coordinates="coordinates" :radius="radius" :label="mapLabel" :zoom="zoom" :lineArray="lineArray")
</template>

<script>
import ViolationBarChart from "@/modules/reports/components/charts/ViolationBarChart.vue";
import ViolationTypeBarChart from "@/modules/reports/components/charts/ViolationTypeBarChart.vue";
import ViolationVehiclePieChart from "@/modules/reports/components/charts/ViolationVehiclePieChart.vue";
import VehicleFilterDialog from "@/modules/global/components/dialog/VehicleFilterDialog";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById.vue";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import ExportDataDialog from "@/modules/global/components/dialog/ExportDataDialog";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import {downloadFile, startDownload} from "@/modules/reports/store/reportUtility";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import Repository from "@/repository";
import moment from "moment";
import MapDialog from "@/modules/global/components/table/MapDialog";
import axios from "axios";
export default {
  components: {
    VehicleColumnById,
    GenericBreadCrumbs,
    VehicleFilterDialog,
    ExportDataDialog,
    VueCtkDateTimePicker,
    VehicleAutoComplete,
    MapDialog,
    ViolationBarChart,
    ViolationTypeBarChart,
    ViolationVehiclePieChart
  },
  data: () => ({
    showDialog: false,
    filterVehicleID:null,
    vehicleID: null,


    loading: true,
    thedate: { start: "2021-08-03 12:00 am", end: null },
    shortcuts: [
      { key: "today", label: "Today", value: 0 },
      { key: "yesterday", label: "Yesterday", value: "-day" },
      { key: "last7Days", label: "Last 7 days", value: 7 },
      { key: "last14Days", label: "Last 14 days", value: 14 },
      { key: "last30Days", label: "Last 30 days", value: 30 }
    ],
    results:[],

    fileLink: null,
    showExportDialog: false,
    fileExport: "",
    breadCrumbsItems: [
      {
        text: "Report",
        disabled: false,
        path: "reports"
      },
      {
        text: "Vehicle Report",
        disabled: true,
        path: "vehiclealertReport"
      }
    ],
    dialog: true,
    items: [
      {
        title: "Export Pdf",
        icon: "cloud_download",
        value: "export_pdf"
      },
      {
        title: "Export Excel",
        icon: "cloud_download",
        value: "export_excel"
      }

    ],
    search: "",

    headers: [
      { text: "Alert", value: "account_id", align: "left", visible: true },
      { text: "Vehicle", value: "vehicle_id", align: "left", visible: true },
      { text: "Street", value: "street", align: "left", visible: true },
      { text: "Time", value: "from_time", align: "left", visible: true },
      { text: "Speed", value: "speed", align: "left", visible: true },
      { text: "Value", value: "green_driving_value", align: "left", visible: true },

      // To insert Loan Details Here
    ],
    coordinates: null,
    radius: null,
    mapLabel: null,
    zoom: 0,
    lineArray: null,
    vehicleStatKey: 0,
    violationVehiclePieChart:null,
    violationStatTypeKey: 1,
    violationTypeBarChart:null,
    chartData: {
      violationBarChart:{
        monthly: null,
        weekly: null,
      },
    },

  }),

  computed: {
    startDate: function(){
      return moment(this.thedate.start,"YYYY-MM-DD h:mm a").format('ddd, Do MMM YYYY')
    },

    endDate: function(){
      if(this.thedate.end == null){
        return ""
      }else{
        return " - " + moment(this.thedate.end,"YYYY-MM-DD h:mm a").format('ddd, Do MMM YYYY')
      }

    },

    filterResults: function() {
      if (this.filterVehicleID){

        return this.results.filter(item => item.vehicle_id == this.filterVehicleID);
      }else{
        return this.results;
      }
    },




    getHeaders: function() {
      let h = [];
      this.headers.forEach(item => {
        if (item.visible == true) {
          h.push(item);
        }
      });
      return h;
    }
  },
  methods: {

    async onShowMap(item) {


      if(item.max_speed == null){
        this.coordinates = item.location;
        this.mapLabel = item.street;
        this.radius = null;
      }else{
        console.log(item)
        axios.get(`/api/trip/getVehiclePathFromTimeRange?vehicle_id=${item.vehicle_id}&from=${item.time}&to=${item.next_time}`).then(response => {
          this.coordinates = item.location;
          this.mapLabel = item.street;
          this.radius = null;
          this.lineArray = response.data;
        })
      }


    },

    onCloseMap() {
      this.coordinates = null;
    },


    getType: function(item){
      if(item.green_driving_type == 1)   return "Hard Acceleration";
      if(item.green_driving_type == 2) return "Hard Braking";
      if(item.green_driving_type == 3) return "Hard Cornering";
      if(item.green_driving_type == null && item.max_speed != null) return "Over speeding";
      if(item.green_driving_type == null && item.unplug != null) return "Device";
      if(item.green_driving_type == null && item.towing != null) return "Towing";
      if(item.green_driving_type == null && item.idling != null) return "Idling";
      if(item.green_driving_type == null && item.crash != null) return "Crash";
    },

    getValue: function(item){
      if(item.green_driving_type == 1 && item.green_driving_value != null)   return (item.green_driving_value/100).toFixed(2) + " G";
      if(item.green_driving_type == 2 && item.green_driving_value != null) return (item.green_driving_value/100).toFixed(2)+ " G";
      if(item.green_driving_type == 3 && item.green_driving_value != null) return (item.green_driving_value/100).toFixed(2)+ " G";
      if(item.green_driving_type == null && item.max_speed != null) return `Duration: ${(item.diff_odometer/1000).toFixed(2)} Km, Max: ${item.max_speed} Kph` ;
      if(item.green_driving_type == null && item.unplug != null) return item.unplug ? 'Unplugged':'Plugged';
      if(item.green_driving_type == null && item.towing != null) return "Towing Detected";
      if(item.green_driving_type == null && item.idling != null) return item.idling;
      if(item.green_driving_type == null && item.crash != null) return "Crash Detected";
    },

    getIcon: function(item){
      if(item.over_speed !== null) return "/static/web/vehicle_overspeed.png";
      if(item.green_driving_type == 1)  return "/static/web/vehicle_accelerate.png";
      if(item.green_driving_type == 2)  return "/static/web/vehicle_brake.png";
      if(item.green_driving_type == 3)  return "/static/web/vehicle_conering.png";
      if(item.green_driving_type == null && item.unplug != null) return "/static/web/vehicle_tracker.png"
      if(item.green_driving_type == null && item.towing != null) return "/static/web/vehicle_towing.png"
      if(item.green_driving_type == null && item.idling != null) return "/static/web/vehicle_idling.png"
      if(item.green_driving_type == null && item.crash != null) return "/static/web/vehicle_crash.png"
    },

    getDate:function(dateIso){
      return moment(new Date(dateIso)).format("h:mm.ss a - ddd, Do MMM YYYY")
    },

    onExport: function(value) {
      let startDate = moment(this.thedate.start,"YYYY-MM-DD h:mm a").toISOString()
      let endDate
      if(this.thedate.end == null){
        endDate  = moment(this.thedate.start,"YYYY-MM-DD h:mm a").endOf("day").toISOString()
      }else{
        endDate = moment(this.thedate.end,"YYYY-MM-DD h:mm a").toISOString()
      }

      let vehicle_ids = "";
      if(this.filterVehicleID !==  null){
        vehicle_ids = "&id=" + this.filterVehicleID;
      }
      this.$store.commit("reports/setDownloadState",false);
      switch (value) {

        case "export_excel":
          this.fileExport = "Excel";
          downloadFile(`/report/vehicleNotificationsExcel?&from=${startDate}&to=${endDate}${vehicle_ids}`,this.headers,this.$store);
          break;
          case "export_pdf":
            this.fileExport = "PDF";
            downloadFile(`/report/vehicleNotificationsPDF?&from=${startDate}&to=${endDate}${vehicle_ids}`,this.headers,this.$store);
            break;
      }
      this.showExportDialog = !this.showExportDialog;
    },



    onDownload: function() {
      // download the file
      startDownload();
      this.onCloseExportDialog();
    },
    onCloseExportDialog: function() {
      this.showExportDialog = false;
    },
    print: function() {
      console.log("print");
    },
    groupStyle: function(value) {
      let obj = {};
      obj["border-bottom"] = "3px solid";
      obj["border-bottom-color"] = value;
      return obj;
    },

    operatorStyle: function(operator) {
      let obj = {};
      obj["border-radius"] = "50%";
      obj["color"] = "#FFFFFF";
      obj["padding"] = "3px";
      obj["margin-right"] = "5px";

      if (operator.group != null) {
        obj["background-color"] = operator.group.color;
      } else {
        obj["background-color"] = "#333333";
      }
      console.log(operator.group);
      return obj;
    },

    onUpdateDate: function() {
      this.showDialog = false;
      this.loadAccountAlerts();
      //TODO: update stuff
    },
    onOpenDialog: function() {
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
      this.showDialog = true;
    },

    onCancelDialog: function() {
      this.showDialog = false;
      // this.thedate = JSON.parse(JSON.stringify(this.originalDate));
    },


    loadAccountAlerts:function() {
      this.filterVehicleID = null;
      this.loading = true;
      let startDate = moment(this.thedate.start,"YYYY-MM-DD h:mm a").toISOString()
      let endDate
      if(this.thedate.end == null){
        endDate  = moment(this.thedate.start,"YYYY-MM-DD h:mm a").endOf("day").toISOString()
      }else{
        endDate = moment(this.thedate.end,"YYYY-MM-DD h:mm a").toISOString()
      }
      Repository.get(`report/violations?from=${startDate}&to=${endDate}`).then((response)=>{
        this.results = response.data;
        this.loading = false;
        // this.onGetVehicleStats();
        // this.onGetTypeStats();
      })
    },

    onGetVehicleStats: function(){
      this.violationVehiclePieChart = [];
      this.filterResults.forEach(element => {
        let isFound = false;
        // console.log(element.vehicle_id)
        this.violationVehiclePieChart.forEach(pieElement => {
           if(pieElement.key === element.vehicle_id) {
             isFound = true;
             pieElement.doc_count  += 1;
           }
        })

        if (isFound === false) {
          this.violationVehiclePieChart.push({key:element.vehicle_id,name:(this.$store.getters["vehicles/getVehicleByID"](element.vehicle_id)).name, doc_count:1});
        }

      })

      this.vehicleStatKey +=1;
    },

    onGetTypeStats: function() {
      this.violationTypeBarChart = [
        {
          key:"Unplug",
          value: 0
        },
        {
          key:"Idling",
          value: 0
        },
        {
          key:"Braking",
          value: 0
        },
        {
          key:"Cornering",
          value: 0
        },
        {
          key:"Acceleration",
          value: 0
        },
        {
          key:"Overspeeding",
          value: 0
        }
      ];
      this.filterResults.forEach(element => {

          // overspeeding
          if (element.over_speeding){
            this.violationTypeBarChart[5].value += 1;
          }

          // Acceleration
          if (element.green_driving_value){
            switch (element.green_driving_type) {
              case 1: this.violationTypeBarChart[4].value +=1; break;
              case 2: this.violationTypeBarChart[2].value +=1; break;
              case 3: this.violationTypeBarChart[3].value +=1; break;
            }
          }

          // Unplug
        if(element.unplug){
          this.violationTypeBarChart[0].value +=1;
        }

        if(element.idling){
          this.violationTypeBarChart[1].value +=1;
        }

      })

      this.violationStatTypeKey += 1;
    },

    onRefresh: function() {

      this.thedate.start = moment().subtract(7, "day").startOf('day').format("YYYY-MM-DD h:mm a");
      this.thedate.end = moment().endOf("day").format("YYYY-MM-DD h:mm a")

      this.loadAccountAlerts();

    },

    onLoadMonthlyStats: function() {
      let startMonth = moment().subtract(6,"months").startOf("month").toISOString()
      let endMonth = moment().endOf("month").endOf("day").toISOString()

      let startWeek =moment().subtract(5,"weeks").startOf("week").toISOString()
      let endWeek =moment().endOf("week").endOf("day").toISOString()

      // Repository.get(`report/vehicleMonthlyViolations?fromMonth=${startMonth}&toMonth=${endMonth}&fromWeek=${startWeek}&toWeek=${endWeek}`).then((response)=>{
      //   console.log(response.data);
      //   this.chartData.violationBarChart.monthly = response.data.monthly;
      //   this.chartData.violationBarChart.weekly = response.data.weekly;
      //
      //
      // })
    }


  },

  mounted() {

    this.onLoadMonthlyStats();
    this.onRefresh();





  }
};
</script>

<style scoped lang="stylus">
.reportHeaderList {
  overflow: auto;
  max-height: 400px;
  min-width: 300px;
}

  .placeClick{
    padding-left 5px
    height 100%
    display flex
    align-items center
    cursor pointer
    border-radius 10px

  }

  .placeClick:hover{
    background-color #FFFFFF
    border #ec037b 1px solid
  }

    .mapDialog{
      position:fixed
      top: 150px
      left: 450px
      right 450px
      bottom 150px;
      background-color #6a6a6a;
    }

</style>
