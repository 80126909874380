export default {
  menuList: [
    { text: "General Settings" },
    {
      children: [
        { text: "Account Settings", to: "accountsettings" },
        { text: "Manage Subscription & Billing", to: "subscriptionbilling" },
        { text: "Groups & Subgroups", to: "groupsettings" },
        { text: "Roles", to: "rolesettings" },
        { text: "Report", to: "reportSettings" },
      ]
    },
    { text: "Feature Settings" },
    {
      children: [
        { text: "Custom Fields", to: "customFieldsSettings" },
        { text: "Custom Dashboard", to: "customDashboardSettings" },
        { text: "Labels", to: "tagSettings" },

      ]
    },
    {
      icon: "dashboard",
      text: "Dashboard",
      // children: [{ text: "Custom Widgets", to: "blankSettings" }]
    },
    {
      icon: "local_shipping",
      text: "Vehicles",
      children: [
        // { text: "Vehicle Status", to: "vehicleStatus" },
        // { text: "Vehicle Types", to: "vehicleTypes" },
        { text: "Fuel Types", to: "fuelTypeSettings" },
        { text: "Expense Types", to: "expenseTypeSettings" },
        // { text: "External Vehicle IDs", to: "vehicleIDs" },
        // { text: "Fuel Settings", to: "fuelSettings" }
      ]
    },
    // {
    //   icon: "check_box",
    //   text: "Inspections",
    //   children: [{ text: "Inspection Settings", to: "inspectionSettings" }]
    // },
    // {
    //   icon: "build",
    //   text: "Maintenance",
    //   to: "Vehicles",
    //   children: [
    //     { text: "Maintenance Settings", to: "maintenanceSettings" },
    //     { text: "Work Order Statuses", to: "workOrderStatuses" }
    //   ]
    // },

    {
      icon: "notifications",
      text: "Reminders",
      children: [
        // { text: "Service Reminder Settings", to: "reminderSettings" },
        { text: "Vehicle Renewal Types", to: "vehicleRenewalTypes" },
        { text: "Contact Renewal Types", to: "contactRenewalTypes" }
      ]
    },
    {
      icon: "category",
      text: "Parts",
      to: "Parts",
      children: [
        { text: "Part Locations", to: "partLocationSettings" },
        { text: "Part Categories", to: "partCategorySettings" },
        { text: "Part Manufacturers", to: "partManufacturerSettings" },
        { text: "Measurement Units", to: "measurementUnitSettings" }
      ]
    },

    {
      icon: "tire_repair",
      text: "Tire Management",
      to: "purchaseOrders",
      children: [
        { text: "Tire Models", to: "tireModelSettings" },
        { text: "Tire Stores", to: "tireStoresSettings" },

      ]
    },
    {
      icon: "assignment",
      text: "Purchase Orders",
      to: "purchaseOrders",
      children: [
        { text: "Purchase Order Settings", to: "purchaseOrderSettings" },
        { text: "Purchase Order Statuses", to: "purchaseOrderStatusSettings" },
        {
          text: "Inventory Adjustment Reasons",
          to: "inventoryAdjustmentReasonSettings"
        }
      ]
    },
  ],

  industry_types: [
    {
      ID: 0,
      name: "Construction & Materials",
      desc:
        "Building and Road Construction, Manufacturing, Building Materials, Mining, Logging, Excavating etc"
    },
    {
      ID: 1,
      name: "Services",
      desc:
        "Janitorial, Home or Auto Repair, Pest Control, Lawn Care, Towing etc."
    },
    {
      ID: 2,
      name: "Transport & Logistics",
      desc: "Bus, Taxi, Priviate Line, Travel/Tour, Courier Service etc."
    },
    {
      ID: 3,
      name: "Education",
      desc: "School Districts, Universities, Child Care, Vocational etc."
    },
    {
      ID: 4,
      name: "Energy & Utilites",
      desc:
        "Oil and Gas, Electric, Water Sewage, Waste Removal, Recycling, Petroleum etc. (Private or Public)"
    },
    {
      ID: 5,
      name: "Food and Beverage",
      desc: "Farming, Ranches, Beverages, Dairy, Meat, Baking etc."
    },
    {
      ID: 6,
      name: "Government",
      desc: "Police, Fire, Armed Forces, Parastatals, County Councils"
    },
    {
      ID: 7,
      name: "Retail",
      desc: "Retail Stores, Newspaers, Distributors, Lumberyards etc."
    },
    {
      ID: 8,
      name: "Health Care",
      desc: "Hospitals, Emergency Response, Hospice Caere etc."
    },
    {
      ID: 9,
      name: "Nonprofit",
      desc:
        "Non Government Organizations, Mission based organizations, churches etc."
    },
    {
      ID: 10,
      name: "Trucking",
      desc: null
    },

    {
      ID: 11,
      name: "Other",
      desc: null
    }
  ],

  country_types: ["Ethiopia", "Kenya", "Uganda", "Rwanda", "Tanzania"],
  currency_types: [
    {
      name: "(KES) Kenya Shillings",
      type: "Ksh.",
      value: 0
    },
    {
      name: "(TZS) Tanzania Shillings",
      type: "Tzh.",
      value: 0
    },
    {
      name: "(UGX) Uganda Shillings",
      type: "Ush.",
      value: 0
    }
  ],
  date_format_types: ["DD-MM-YYYY", "MM-DD-YYYY"],
  time_format_types: [
    { name: "12 Hour", value: "12h" },
    { name: "24 Hour", value: "24h" }
  ],
  usage_types: [
    { name: "Miles", value: "Miles", unit: "Mi" },
    { name: "Kilometers", value: "Kilometers", unit: "Km" },
    { name: "Hours", value: "Hours", unit: "Hr" }
  ],
  time_zone_types: [
    {
      useDaylightTime: false,
      value: 3,
      city: "(GMT+03:00) Nairobi"
    },
    {
      useDaylightTime: false,
      value: 3,
      city: "(GMT+03:00) Bagdad"
    }
  ],
  system_measurment_types: ["imperial", "metric"],
  fuel_volume_types: [
    { name: "Gallons", unit: "gi" },
    { name: "Litres", unit: "l" }
  ],
  tax_types: ["(%) Percentage", "Fixed"]
};
