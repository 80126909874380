<template lang="pug">
  div(v-if="$store.state.expenses.expense_data.length > 0")
    v-toolbar(dense flat)
      generic-bread-crumbs.py-1(:items="breadCrumbsItems")
      v-spacer( )
      .d-flex.align-center()
        .mr-2(v-if="$store.state.expenses.expense_data.length > 1")
          v-btn.mx-4(icon small @click="previous")
            v-icon() skip_previous
          v-btn.mx-4(icon small @click="next")
            v-icon() skip_next
        v-btn.mx-2(color="primary" small @click="editRecord") Edit Record
    v-divider()
    v-container.mt-8.pa-8.white(fluid)
      v-row()
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:body)
              div.mt-2.mb-2.pb-2
                table.DetailTable.body-2.fill-width
                  tr(v-if="getExpense.vehicle")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Vehicle
                    td
                      vehicle-column( :vehicle="getExpense.vehicle")
                  tr
                    td.goRight.grey--text.text--darken-1(style="width:120px")
                      .mr-3.primary--text Total ({{$store.state.profile.userInfo.account.region_settings.currency}})
                    td.primary--text.font-weight-bold.text-lg-h6 {{getExpense.total}}

                  tr(v-if="getExpense.expense_type")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Expense Type
                    td {{getExpense.expense_type.name}}

                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Date
                    td()
                      div() {{getExpense.date_time | moment("ddd, Do MMM YYYY &ndash; h:mm a")}}
                  tr(v-if="getExpense.merchant")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Merchant
                    td {{getExpense.merchant.name}}
                  tr
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Description
                    td {{getExpense.description}}
                  tr.mb-0(v-if="getExpense.reported_by")
                    td.goRight.grey--text.text--darken-1
                      .mr-3 Report By
                    td()
                      contact-column-by-id(:contact_id="getExpense.reported_by.id")
          location-map-panel.mt-0(v-if="getExpense.location" :coordinates="getExpense.location" :title="`Location`")
        v-col(md="6" sm="12")
          base-panel()
            template(v-slot:title)
              | Expense Items


            template(v-slot:body)
              template(v-if="getExpense.expense_items")
                table(id="expenseItemTable2")
                  tr
                    th() Qty
                    th.alignLeft() Item
                    th.alignRight(style="text-alight:right") @
                    th.alignRight(style="text-alight:right") Amount
                  tr(v-for="(item,index) in getExpense.expense_items" :key="index" :class="index%2==0?'':'grey lighten-5'")
                    td.qty() {{item.quantity}}
                    td.item() {{item.item}}
                    td.costperitem() {{item.cost_per_item}}
                    td.amount() {{item.amount}}
              template(v-else)
                .d-flex.justify-center.align-center()
                  .ma-8.grey--text() No Data

          base-panel()
            template(v-slot:title)
              | Photos

            template(v-slot:body)
              attach-fill-form( :schema="imagesSchama" :data="{value:getExpense.photos_id}" @onMenuAction="onMenuActionImages" :new-menu="moreMenu" :hide-button="false"  )


</template>
<script>
import BasePanel from "@/modules/global/components/panel/basePanel";
import VehicleColumn from "@/modules/global/components/table/VehicleColumn";
import ContactColumnById from "@/modules/global/components/table/ContactColumnById.vue";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";
import GenericBreadCrumbs from "@/modules/global/components/others/GenericBreadCrumbs";
import LocationMapPanel from "@/modules/global/components/panel/LocationMapPanel";

export default {
  components: {
    BasePanel,
    VehicleColumn,
    AttachFillForm,
    GenericBreadCrumbs,
    LocationMapPanel,
    ContactColumnById
  },
  data: () => ({
    title: "header",
    answer: "Sengenge ni Ngombe",
    breadCrumbsItems: [
      {
        text: "Expenses",
        disabled: false,
        path: "fuels",
        tabID: 1
      },
      {
        text: "Expense Detail",
        disabled: true
      }
    ],
    imagesSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true
    },
    documentsSchama: {
      name: "Attach",
      id: null,
      value: [],
      label: "Upload Fuel Documents",
      hint: null,
      type: "document",
      image_size: "small",
      multiple: true
    },
    moreMenu: [
      {title: "Download", icon: "cloud_download", action: "download"}
    ],
    items: [
      {
        title: "Copy Link to Clipboard",
        icon: "content_copy",
        value: "copy_link"
      },
      {
        title: "Open Link",
        icon: "open_in_new",
        value: "open_link"
      }

    ],
  }),
  computed: {
    getExpense: function () {
      return this.$store.getters["expenses/getCurrentExpense"];
    }
  },
  methods: {
    onCloseDialog: function () {
    },
    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          // this.$store.commit("fuel/deleteImage", value.theObject.id);
          break;
      }
    },



    next(){
      this.$store.commit("expenses/next");
    },
    previous() {
      this.$store.commit("expenses/previous");
    },

    editRecord: function(){
      // this.$store.commit("fuel/setCurrentIndexByID", this.$route.params.fuelID);
      let currentID = this.$store.getters["expenses/getCurrentExpense"].id
      this.$router.push({
        name: "expenseForm",
        params: {vehicleID: currentID}
      }).catch(()=>{});

    },

  },
  mounted() {

    // if (this.$store.state.expenses.expense_data.length < 2) {
    //   console.log(this.$route.params.expenseID)
    //   this.$store
    //       .dispatch("expenses/fetchExpense", this.$route.params.expenseID)
    //       .then(() => {
    //       });
    // }
  }
};
</script>
<style lang="stylus" scoped>
.DetailTable
  tr
    margin 0
    padding 0
  td
    border-bottom 1px solid #EEEEEE
    margin 0
    padding 0

    padding-top 6px
    padding-bottom 8px

    #expenseItemTable{
      width : 400px;
      border-collapse: collapse;
      font-size: 10px;
      margin-top: 10px;
      margin-bottom: 10px
    }

#expenseItemTable2{
  width : 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px
}

#expenseItemTable2 th{
  border 1px solid #949494;
  background-color: #d3d3d3;
  font-size: 14px;
  padding: 4px;
}
#expenseItemTable2 td {
  border 1px solid #CCC;
  color: #3f3f3f;
  padding: 2px;
}
#expenseItemTable2 .qty {
  text-align: center;
  width: 30px;
}

#expenseItemTable2 .item{
  width: 180px;
  padding-left: 10px;
}

#expenseItemTable2 .costperitem{
  width: 60px;
  text-align: right;
  padding-right: 10px;
}

#expenseItemTable2 .amount{
  width: 80px;
  text-align: right;
  padding-right: 10px;
}


#expenseItemTable2 .alignRight{
  text-align: right;
  padding-right: 10px;
}

#expenseItemTable2 .alignLeft{
  text-align: left;
  padding-left: 10px;
}
</style>
