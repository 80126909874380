<template lang="pug">
  v-container()
    v-row()
      v-col.pl-0(cols="7")
        v-menu(ref='menuDate', :close-on-content-click='false', v-model='menuDate', :nudge-right='40', :return-value.sync='date',  transition='scale-transition', offset-y, , min-width='290px')
          template(#activator="{on}")
            v-text-field(v-on="on", v-model='formattedDate', :label='labelDate', prepend-icon='event', readonly,  :hide-details="true")
          v-date-picker(v-model='date', @input='$refs.menuDate.save();')
      v-col.pr-0(cols="5")
        v-menu(ref="menuTime", :close-on-content-click='false', v-model="menuTime", :nudge-right="40", :return-value.sync='time', transition='scale-transition', offset-y, , min-width='290px')
          template(#activator="{on}")
            v-text-field(v-model="formattedTime"   :label='labelTime',  prepend-icon="access_time"  readonly v-on="on" :hide-details="true")
          v-time-picker(v-model="time", , @click:minute="$refs.menuTime.save()")
</template>
<script>
import moment from "moment";
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    labelDate: {
      type: String,
      default: "Select Date"
    },
    labelTime: {
      type: String,
      default: "Select Time"
    }
  },
  data: () => ({
    menuDate: false,

    menuTime: false
  }),

  mounted() {
    if(this.value &&  new Date(this.value).getTime() < 0 ){
      this.$emit("input", new Date().toISOString());
    }
  },

  computed: {
    valueDate: {
      get: function() {
        console.log(this.value);
        return new Date(this.value);
      },
      set: function(value) {
        // console.log("saving Date timestamp: " + value.getTime());
        this.$emit("input", value.toISOString());
      }
    },

    date: {
      get: function() {
        return this.valueDate.toISOString().substr(0, 10);
      },
      set: function(value) {
        if (value != undefined) {
          this.onSetDate(value);
        }
      }
    },

    time: {
      get: function() {
        return moment(this.valueDate).format("HH:mm");
      },
      set: function(value) {
        if (value != undefined) {
          this.onSetTime(value);
        }
      }
    },

    formattedTime: {
      get: function() {
        return moment(this.valueDate).format("h:mm a");
      },
      set: function() {}
    },

    formattedDate: {
      get: function() {
        return moment(this.valueDate).format("DD MMMM YYYY");
      },
      set: function() {}
    }
  },

  methods: {
    onSetTime: function(value) {
      let t = this.valueDate;
      // console.log("time is: " + value);

      t.setHours(
        parseInt(value.split(":")[0]),
        parseInt(value.split(":")[1]),
        0
      );

      this.valueDate = t;
    },
    onSetDate: function(value) {
      let t = this.valueDate;
      t.setFullYear(value.split("-")[0]);
      t.setMonth(value.split("-")[1] - 1);
      t.setDate(value.split("-")[2]);
      this.valueDate = t;
    }
  }
};
</script>
<style scoped lang="stylus"></style>
