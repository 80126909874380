import AccountSettings from "@/modules/settings/layouts/subLayout/AccountSettings";
import SubscriptionsBilling from "@/modules/settings/layouts/subLayout/SubscriptionsBilling";
import GroupSettings from "@/modules/settings/layouts/subLayout/GroupSettings";
import RoleSettings from "@/modules/settings/layouts/subLayout/RoleSettings";

import VehicleRenewalTypes from "@/modules/reminders/layouts/vehicleRenewal/vehicleRenewalTypes_Settings";
import ContactRenewalTypes from "@/modules/reminders/layouts/contactRenewal/contactRenewalTypes_Settings";

import RoleForm from "@/modules/settings/components/forms/RoleForm";
import GroupForm from "@/modules/settings/components/forms/GroupForm";
import TagForm from "@/modules/settings/components/forms/TagForm";
import TagSettings from "@/modules/settings/layouts/subLayout/TagSettings";
import FuelTypeForm from "@/modules/settings/components/forms/FuelTypeForm";
import FuelTypeSettings from "@/modules/settings/layouts/subLayout/FuelTypeSettings";
import ExpenseTypeSettings from "@/modules/settings/layouts/subLayout/ExpenseTypeSettings";
import ExpenseTypeForm from "@/modules/settings/components/forms/ExpenseTypeForm";
import VehicleRenewalTypeForm from "@/modules/reminders/components/forms/VehicleRenewalTypeForm";
import ContactRenewalTypeForm from "@/modules/reminders/components/forms/ContactRenewalTypeForm";

import PartLocationSettings from "@/modules/settings/layouts/subLayout/PartLocationSettings";
import PartManufacturerSettings from "@/modules/settings/layouts/subLayout/PartManufacturerSettings";
import PartCategorySettings from "@/modules/settings/layouts/subLayout/PartCategorySettings";
import MeasurementUnitSettings from "@/modules/settings/layouts/subLayout/MeasurementUnitSettings";
import PurchaseOrderSettings from "@/modules/settings/layouts/subLayout/PurchaseOrderSettings";
import PurchaseOrderStatusSettings from "@/modules/settings/layouts/subLayout/PurchaseOrderStatusSettings";
import InventoryAdjustmentReasonSettings from "@/modules/settings/layouts/subLayout/InventoryAdjustmentReasonSettings";

import PartLocationForm from "@/modules/settings/components/forms/PartLocationForm";
import PartCategoryForm from "@/modules/settings/components/forms/PartCategoryForm";
import PartManufacturerForm from "@/modules/settings/components/forms/PartManufacturerForm";
import MeasurementUnitForm from "@/modules/settings/components/forms/MeasurementUnitForm";
import InventoryAdjustmentReasonForm from "@/modules/settings/components/forms/InventoryAdjustmentReasonForm";
import PurchaseOrderStatusForm from "@/modules/settings/components/forms/PurchaseOrderStatusForm";
import CustomFieldsSettings from "@/modules/settings/layouts/subLayout/CustomFieldsSettings";
import CustomeFieldsForm from "@/modules/settings/components/forms/CustomeFieldsForm";
import BlankSettings from "@/modules/settings/layouts/subLayout/BlankSettings";
import ReportSettings from "@/modules/settings/layouts/subLayout/ReportSettings";
import CustomDashboardForm from "@/modules/settings/components/forms/CustomDashboardForm";
import CustomDashboardSettings from "@/modules/settings/layouts/subLayout/CustomDashboardSettings";

import TireStores from "@/modules/settings/layouts/subLayout/TireStoresSettings";
import TireModelSettings from "@/modules/settings/layouts/subLayout/TireModelSettings";
import TireStoreForm from "@/modules/inventory/tire_management/components/forms/TireStoreForm";
import TireModelForm from "@/modules/inventory/tire_management/components/forms/TireModelForm";
export const SETTINGS_ROUTES = [
  {
    path: "blankSettings",
    name: "blankSettings",
    component: BlankSettings
  },
  {
    path: "accountsettings",
    name: "accountsettings",
    component: AccountSettings
  },
  {
    path: "subscriptionbilling",
    name: "subscriptionbilling",
    component: SubscriptionsBilling
  },
  {
    path: "groupsettings",
    name: "groupsettings",
    component: GroupSettings
  },
  {
    path: "rolesettings",
    name: "rolesettings",
    component: RoleSettings
  },
  {
    path: "role/:roleID",
    name: "roleform",
    component: RoleForm
  },
  {
    path: "reportSettings",
    name: "reportSettings",
    component: ReportSettings
  },
  {
    path: "customFieldsSettings",
    name: "customFieldsSettings",
    component: CustomFieldsSettings
  },
  {
    path:"customFieldsForm/:customFieldID",
    name:"customFieldsForm",
    component: CustomeFieldsForm
  },
  {
    path: "group/:groupID",
    name: "groupform",
    component: GroupForm
  },
  {
    path: "tag/:tagID",
    name: "tagform",
    component: TagForm
  },

  {
    path: "tagSettings",
    name: "tagSettings",
    component: TagSettings
  },

  {
    path: "customDashboard/:customDashboardID",
    name: "customDashboardForm",
    component: CustomDashboardForm
  },

  {
    path: "customDashboardSettings",
    name: "customDashboardSettings",
    component: CustomDashboardSettings
  },

  {
    path: "fuelType/:fuelTypeID",
    name: "fuelTypeForm",
    component: FuelTypeForm
  },

  {
    path: "fuelTypeSettings",
    name: "fuelTypeSettings",
    component: FuelTypeSettings
  },

  {
    path: "expenseTypeSettings",
    name: "expenseTypeSettings",
    component: ExpenseTypeSettings
  },
  {
    path: "expenseType/:expenseTypeID",
    name: "expenseTypeForm",
    component: ExpenseTypeForm
  },

  {
    path: "vehicleRenewalTypes",
    name: "vehicleRenewalTypes",
    component: VehicleRenewalTypes
  },
  {
    path: "vehicleRenewalType/:vehicleRenewalTypeID",
    name: "vehicleRenewalTypeForm",
    component: VehicleRenewalTypeForm
  },
  {
    path: "contactRenewalTypes",
    name: "contactRenewalTypes",
    component: ContactRenewalTypes
  },
  {
    path: "contactRenewalType/:contactRenewalTypeID",
    name: "contactRenewalTypeForm",
    component: ContactRenewalTypeForm
  },
  {
    path: "partLocationSettings",
    name: "partLocationSettings",
    component: PartLocationSettings
  },
  {
    path: "partLocation/:partLocationID",
    name: "partLocationForm",
    component: PartLocationForm
  },
  {
    path: "partCategorySettings",
    name: "partCategorySettings",
    component: PartCategorySettings
  },
  {
    path: "partCategory/:partCategoryID",
    name: "partCategoryForm",
    component: PartCategoryForm
  },
  {
    path: "partManufacturerSettings",
    name: "partManufacturerSettings",
    component: PartManufacturerSettings
  },
  {
    path: "partManufacturer/:partManufacturerID",
    name: "partManufacturerForm",
    component: PartManufacturerForm
  },
  {
    path: "measurementUnitSettings",
    name: "measurementUnitSettings",
    component: MeasurementUnitSettings
  },
  {
    path: "measurementUnit/:measurementUnitID",
    name: "measurementUnitForm",
    component: MeasurementUnitForm
  },
  {
    path: "purchaseOrderSettings",
    name: "purchaseOrderSettings",
    component: PurchaseOrderSettings
  },
  {
    path: "purchaseOrderStatusSettings",
    name: "purchaseOrderStatusSettings",
    component: PurchaseOrderStatusSettings
  },
  {
    path: "purchaseOrderStatus/:purchaseOrderStatusID",
    name: "purchaseOrderStatusForm",
    component: PurchaseOrderStatusForm
  },
  {
    path: "inventoryAdjustmentReasonSettings",
    name: "inventoryAdjustmentReasonSettings",
    component: InventoryAdjustmentReasonSettings
  },
  {
    path: "inventoryAdjustmentReason/:inventoryAdjustmentReasonID",
    name: "inventoryAdjustmentReasonForm",
    component: InventoryAdjustmentReasonForm
  },
  {
    path: "tireStoresSettings",
    name: "tireStoresSettings",
    component: TireStores
  },
  {
    path: "tireStoreForm/:tireStoreID",
    name: "tireStoreForm",
    component: TireStoreForm
  },
  {
    path: "tireModelSettings",
    name: "tireModelSettings",
    component: TireModelSettings
  },
  {
    path: "tireModelForm/:tireModelID",
    name: "tireModelForm",
    component: TireModelForm
  }
];
