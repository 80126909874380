<template lang="pug">
  div(v-if="item")
    //- if it is an image
    template(v-if="getMimeType")
      div(:style="getPhotoDocument")
        div(:style="getImageCoverStyle"  @click="showDialog()")
          v-layout.ma-1(align-start justify-end v-if="size==='small'")
            more-drop-down-menu(v-if="!hideButton" :items="menuitems" :size="size" style="opacity:0.8" @onClick="onClick")
        div(:style="getFileVehicleDetails")
          .fileInfo.grey--text.text--darken-2
            v-tooltip(bottom)
              span {{item.module}} photo
            .cut-text.caption(:style="getCaptionStyle")
              span(:class="[ this.size==='large' ? 'title' : '']") {{getTrimName}} &nbsp;
            v-tooltip(bottom v-if="!(this.size === 'small')" )
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" small) info_outline
              span
                b Original File Name:
                <br/>{{item.original_name}}
          more-drop-down-menu( v-if="!hideButton && !(this.size === 'small')" :items="menuitems" @onClick="onClick" )
        .largeDescription( v-if="this.size === 'large'")
          | {{item.description}}
    //- if it is text
    template(v-else)
      .textStyle()
        | {{item.name}}
        more-drop-down-menu.ml-2(v-if="!hideButton" :items="menuitems" @onClick="onClick" :size="size" style="opacity:0.8")
    a(:href="`/media/${item.account_id}/${item.file_name}`" ref="downloadLink" :download="item.original_name" hidden)
    v-dialog(v-if="dialog" v-model="dialog"  width="unset")
      v-card()
        v-toolbar(color='primary' dark style="position:fixed;")
          v-toolbar-title(v-html="item.original_name")
          v-btn.mr-2(text color="white" small @click="$refs.downloadLink.click();" )
            v-icon( ) download
          v-btn.mx-4(color="white" medium @click="showDialog()")
            v-icon(color="primary") close
        img(:src="`/media/${this.item.account_id}/${this.item.file_name}`" style="display:block")
</template>

<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";

export default {
  components: {
    MoreDropDownMenu
  },
  props: {
    item: Object,
    size: String,
    moreMenu: Array,
    newMenu: Array,
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    icons: {
      vehicle: "local_shipping",
      service: "build",
      issues: "warning",
      fuel: "local_gas_station",
      work_order: "assignment"
    },

    // Menu Items for Drop Menu
    menuitems: [
      {title: "Edit", icon: "edit", action: "edit"},
      {title: "Delete", icon: "delete", action: "delete"},
      {title: "Download", icon: "cloud_download", action: "download"}
    ]
  }),

  mounted() {
    // add aditional Menu items in the thumbnail if needed
    if (this.moreMenu) {
      this.moreMenu.forEach(item => {
        this.menuitems.push(item);
      });
    }

    if (this.newMenu) {
      this.menuitems = this.newMenu;
    }
  },

  computed: {

    getCaptionStyle: function() {
      switch (this.size) {
        case "small":
          return {width:"150px"};
        case "large":
          return  {width:"240px"};
        default:
          return {width:"540"};
      }
    },
    // Styles Formating
    getPhotoDocument: function () {
      let mediumStyle = {
        "background-color": "#ffffff",
        "border-radius": "8px",
        border: "1px solid #BEBEBE",
        width: "300px",
        padding: "10px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      let smallStyle = {
        "background-color": "#ffffff",
        "border-radius": "6px",
        border: "1px solid #BEBEBE",
        width: "150px",
        padding: "5px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      let largeStyle = {
        "background-color": "#ffffff",
        "border-radius": "15px",
        border: "1px solid #BEBEBE",
        width: "600px",
        padding: "14px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      };

      switch (this.size) {
        case "small":
          return smallStyle;
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getImageCoverStyle: function () {
      let mediumStyle = {
        width: "280px",
        height: "180px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.thumbnail}")` //to resize for thumbnail i.e. have a way to get the thumbnail and cache it
      };

      let smallStyle = {
        width: "140px",
        height: "90px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.thumbnail}")` //to resize for thumbnail i.e. have a way to get the thumbnail and cache it
      };

      let largeStyle = {
        width: "560px",
        height: "360px",
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-image": `url("/media/${this.item.account_id}/${this.item.file_name}")`
      };

      switch (this.size) {
        case "small":
          return smallStyle;
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getFileVehicleDetails: function () {
      let mediumStyle = {
        "padding-top": "10px",
        display: "flex",
        "flex-direction": "row",
        width: "100%"
      };

      let largeStyle = {
        padding: "15px 15px",
        display: "flex",
        "flex-direction": "row",
        width: "100%"
      };

      switch (this.size) {
        case "small":
          return {};
        case "large":
          return largeStyle;
        default:
          return mediumStyle;
      }
    },

    getMimeType: function () {
      if (
          this.item.mime_type === "image/bmp" ||
          this.item.mime_type === "image/jpeg" ||
          this.item.mime_type === "image/png"
      )
        return true;
      else return false;
    },
    getTrimName: function () {
      if (this.item.name.length > 60) {
        return ""
      } else {
        return this.item.name
      }

    },
  },

  methods: {

    showDialog: function () {
      this.dialog = !this.dialog;
    },
    onClick: function (value) {

      value.theObject = this.item;
      if (value.action == "download") {
        this.$refs.downloadLink.click();
      } else {
        this.$emit("onMenuAction", value);
      }
    },

    downloadFile: function () {
    }
  }
};
</script>
<style lang="stylus" scoped>
.theIconMenus {
  display: flex;
  justify-content: space-between;
  padding: 2px 2px;
}

.fileInfo {
  width: 100%;
}

.largeDescription {
  text-align: left;
  width: 100%;
  padding: 0px 15px;
}

.textStyle {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #BEBEBE;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  padding 2px 5px 0px 5px;
  white-space: nowrap;
  text-align center;
  direction: rtl;
}
</style>
