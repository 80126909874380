<template lang="pug">
div(v-if="tireModel" )

  .ml-0(v-if="tireModel != null" @click="openTireModelDetail(tireModel.id)")
    .d-flex.align-center.tyreComponent.py-1()
      image-thumbnail.mr-3(v-for="(itemID,index) in tireModel.photos_id" :key="index" :width='60', :height='60', :src="itemID")
      .d-flex.flex-column()
        .font-weight-bold() {{tireModel.name}}
        .caption.primary--text() {{tireModel.size}}
        .caption.grey--text.text--darken-1() {{tireModel.pattern}} [{{tireModel.load_index}}/{{tireModel.speed_rating}}]

</template>
<script>
import axios from "axios";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
export default {
  components: {
    ImageThumbnail,
  },
  props: {
    tireModel_id: String,
    clickToDetail:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    tireModel: null
  }),
  watch:{
    tireModel_id:function(oldVal,newVal){
      if(oldVal !== newVal){
        this.getTireModel();
      }
    }
  },
  methods: {
    getTireModel: function(){
      let tempTireModel = this.$store.getters["tireModel/getTireModelById"](this.tireModel_id);
      if (tempTireModel == undefined) {
        axios.get(`/api/tireModel/${this.tireModel_id}`).then((response)=>{
          this.tireModel = response.data;
        });
      } else {
        this.tireModel = tempTireModel;
      }
    },
    openTireModelDetail: function (tireModelID) {
      if(this.clickToDetail){
        this.$store.commit("tireModel/setCurrentIndexByID", tireModelID);
        // this.$router.push({
        //   name: "tireModelDashboard",
        //   params: {contactID: tireModelID}
        // }).catch(()=>{});
      }

    }
  },
  mounted() {
    this.getTireModel();
  }
};
</script>
<style lang="stylus" scoped>
.nameColumn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nameColumn img {
  width: 80px;
  height: 60px;
}

.nameColumn, .nameColumnInfo {
  margin-left: 15px;
}

.nameColumn, .nameColumnTitle {
  font-weight: bold;
  margin-bottom: 3px;
  padding: 0px;
}

.nameColumnDescription {
  font-size: 10px;
  color: gray;
  padding: 0px;
}

.underline {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
</style>
