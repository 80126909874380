import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const TIREMODEL_API = "/tireModel";

// ----------------------- STATE ------------------------- //
const state = {
    tireModel_data: [],
    currentIndex: -1,
    selectedTireModels: [],
};
// ----------------------- GETTERS ----------------------- //
const getters = {
    getTireModel: state => getField(state.tireModel_data[state.currentIndex]),

    getCurrentTireModel: state => {
        return state.tireModel_data[state.currentIndex];
    },

    getTireModelById: state => tireModelID => {
        return state.tireModel_data.find(item => item.id == tireModelID);
    }

}

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateTireModel(state, payload) {
        updateField(state.tireModel_data[state.currentIndex], payload);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.tireModel_data.findIndex(tireModel => tireModel.id == theID);
    },

    loadAllTireModels(state, payload) {
        state.tireModel_data = payload.data;
        state.currentIndex = 0;
    },

    addTireModel(state, payload) {
        state.tireModel_data.push(payload.data);
        state.currentIndex = state.tireModel_data.length - 1;
    },

    saveTireModel(state, payload) {
        state.tireModel_data[state.currentIndex] = payload.data;
    },

    fetchTireModel(state, payload) {
        if (state.currentIndex == -1) {
            state.tireModel_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.tireModel_data[state.currentIndex] = payload.data;
        }
    },
    deleteTireModel(state, payload) {
        state.currentIndex = state.tireModel_data.findIndex(
            tireModel => tireModel.id == payload.data.id
        );
        state.tireModel_data.splice(state.currentIndex, 1);
    },

    deleteImage(state, payload) {
        let index = state.tireModel_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );

        state.tireModel_data[state.currentIndex].photos_id.splice(index, 1);
    },

    reset(state) {
        state.tireModel_data = []
        state.currentIndex = -1
    },

    setSelectedTireModel(state, payload) {
        state.selectedTireModels = payload;
    },

    deleteSelectedTireModels(state, payload) {
        payload.data.forEach(element => {
            let theIndex = state.tireModel_data.findIndex(
                (tireModel) => tireModel.id == element
            );
            state.tireModel_data.splice(theIndex, 1);
        })
    },

}

//------------------ ACTIONS -------------------//
const actions = {
    async loadAllTireModels({commit}) {
        try {
            commit("loadAllTireModels", await Repository.get(`${TIREMODEL_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async addTireModel({commit}) {
        await Repository.get(`${TIREMODEL_API}/new`)
            .then(response => {
                commit("addTireModel", response);
            })
            .catch(error => {
                console.log(error);
            });
    },
    async saveTireModel({state, commit}) {
        try {
            commit(
                "saveTireModel",
                await Repository.post(
                    `${TIREMODEL_API}`,
                    state.tireModel_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },
    async fetchTireModel({commit}, payloadID) {
        try {
            commit("fetchTireModel", await Repository.get(`${TIREMODEL_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteTireModel({commit}, payloadID) {
        try {
            commit(
                "deleteTireModel",
                await Repository.delete(`${TIREMODEL_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async searchTireModel({commit}, searchTerm) {
        try {
            commit(
                "loadAllTireModels",
                await Repository.get(`${TIREMODEL_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}