import {getField, updateField} from "vuex-map-fields";
import Repository from "@/repository";

const TIRE_API = "/tire";

// ----------------------- STATE ------------------------- //
const state = {
    tire_data: [],
    currentIndex: -1,
    selectedTires: [],
};

// ----------------------- GETTERS ----------------------- //
const getters = {
    getTire: state => getField(state.tire_data[state.currentIndex]),

    getCurrentTire: state => {
        return state.tire_data[state.currentIndex];
    },

    getSelectedTires: state => {
        return state.selectedTires;
    },
    getTireByStatus: state => status => {
        let tempList = [];
        switch (status) {
            case "open":
                tempList = state.tire_data.filter(
                    item =>
                        item.closed_date_time == null && item.service_date_time == null
                );
                break;
            case "overdue":
                tempList = state.tire_data.filter(item => {
                    if (item.due_date_time != null) {
                        return (
                            new Date(item.due_date_time).getTime() < new Date().getTime()
                        );
                    } else {
                        return false;
                    }
                });
                break;
            case "resolved":
                tempList = state.tire_data.filter(
                    item => item.service_date_time != null
                );
                break;
            case "closed":
                tempList = state.tire_data.filter(
                    item => item.closed_date_time != null
                );
                break;
        }
        return tempList;
    },

    getVehicleFaults: state => vehicleID => {
        return state.tire_data.filter(item => item.vehicle_id == vehicleID);
    },

    getVehiclePendingFaults: state => vehicleID => {
        // todo: getVehiclePeningFaults
        return state.tire_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getReportedTiresByUser: state => contactID => {
        return state.tire_data.filter(item => item.reported_by_id == contactID)
            .length;
    },
    getReportedAssignedByUser: state => contactID => {
        return state.tire_data.filter(item => item.assigned_to_id == contactID)
            .length;
    },

    getVehicleOpenFaults: state => vehicleID => {
        // todo: get Open Faults
        return state.tire_data.filter(item => item.vehicle_id === vehicleID)
            .length;
    },

    getOverdueTires: state => {
        return state.tire_data.filter(item => {
            if (item.due_date_time) {
                let theDate = new Date(item.due_date_time).getTime();
                let currentDate = new Date().getTime();
                return theDate < currentDate;
            } else {
                return false;
            }
        });
    }
};

// ----------------------- MUTATIONS -------------------- //
const mutations = {
    updateTire(state, payload) {
        updateField(state.tire_data[state.currentIndex], payload);
    },

    setTireDefaultsByModel(state, tireModel) {
        console.log(tireModel);
        state.tire_data[state.currentIndex].purchase_tread_depth = tireModel.purchase_tread_depth
        state.tire_data[state.currentIndex].purchase_cost = tireModel.cost
        state.tire_data[state.currentIndex].purchase_warranty = tireModel.warranty_period
    },

    deleteImage(state, payload) {
        let index = state.tire_data[state.currentIndex].photos_id.findIndex(
            item => item === payload
        );
        state.tire_data[state.currentIndex].photos_id.splice(index, 1);
    },

    deleteDocument(state, payload) {
        let index = state.tire_data[state.currentIndex].documents_id.findIndex(
            item => item === payload
        );
        state.tire_data[state.currentIndex].documents_id.splice(index, 1);
    },

    setCurrentIndexByID(state, theID) {
        state.currentIndex = state.tire_data.findIndex(tire => tire.id == theID);
    },

    loadAllTires(state, payload) {
        state.tire_data = payload.data;
        state.currentIndex = 0;
    },

    loadVehicleTires(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.tire_data = payload.data;
    },

    loadContactTires(state, payload) {
        if (payload.data.length > 0) {
            state.currentIndex = 0;
        }
        state.tire_data = payload.data;
    },

    addTire(state, payload) {
        state.tire_data.push(payload.data);
        state.currentIndex = state.tire_data.length - 1;
    },

    saveTire(state, payload) {
        state.tire_data[state.currentIndex] = payload.data;
    },

    fetchTire(state, payload) {
        if (state.currentIndex == -1) {
            state.tire_data.push(payload.data);
            state.currentIndex = 0;
        } else {
            state.tire_data[state.currentIndex] = payload.data;
        }
    },

    deleteTire(state, payload) {
        state.currentIndex = state.tire_data.findIndex(
            tire => tire.id == payload.data.id
        );
        state.tire_data.splice(state.currentIndex, 1);
    },

    openTire(state, payload) {
        state.currentIndex = state.tire_data.findIndex(
            tire => tire.id == payload.data.id
        );
        state.tire_data[state.currentIndex] = payload.data;
    },

    closeTire(state, payload) {
        state.currentIndex = state.tire_data.findIndex(
            tire => tire.id == payload.data.id
        );
        state.tire_data[state.currentIndex] = payload.data;
    },

    cleanDataBeforeSaving(state) {
        state.tire_data[state.currentIndex].odometer = parseInt(
            state.tire_data[state.currentIndex].odometer
        );
        state.tire_data[state.currentIndex].vehicle = null;
        state.tire_data[state.currentIndex].reported_by = null;
        state.tire_data[state.currentIndex].assigned_to = null;
        state.tire_data[state.currentIndex].tags = null;
    },

    reset(state) {
        state.tire_data = []
        state.currentIndex = -1
    },



    setSelectedTires(state, payload) {
        state.selectedTires = payload;
    },

    deleteSelectedTires(state, payload) {
        payload.data.forEach(element => {
            let theIndex = state.tire_data.findIndex(
                (tire) => tire.id == element
            );
            state.tire_data.splice(theIndex, 1);
        })
    }
};

// ----------------------- ACTIONS -------------------- //
const actions = {
    async loadAllTires({commit}) {
        try {
            commit("loadAllTires", await Repository.get(`${TIRE_API}`));
        } catch (error) {
            console.log(error);
        }
    },

    async loadVehicleTires({commit}, vehicle_id) {
        try {
            console.log(vehicle_id);
            commit(
                "loadVehicleTires",
                await Repository.get(`${TIRE_API}/vehicle/${vehicle_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async loadContactTires({commit}, contact_id) {
        try {
            commit(
                "loadContactTires",
                await Repository.get(`${TIRE_API}/contact/${contact_id}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async addTire({commit}) {
        await Repository.get(`${TIRE_API}/new`)
            .then(response => {
                commit("addTire", response);
            })
            .catch(error => {
                console.log(error);
            });
    },

    async saveTire({state, commit}) {
        try {
            console.log(state.tire_data[state.currentIndex]);
            commit("cleanDataBeforeSaving");

            commit(
                "saveTire",
                await Repository.post(
                    `${TIRE_API}`,
                    state.tire_data[state.currentIndex]
                )
            );
        } catch (error) {
            console.log(error);
        }
    },

    async fetchTire({commit}, payloadID) {
        try {
            commit("fetchTire", await Repository.get(`${TIRE_API}/${payloadID}`));
        } catch (error) {
            console.log(error);
        }
    },

    async deleteTire({commit}, payloadID) {
        try {
            commit(
                "deleteTire",
                await Repository.delete(`${TIRE_API}/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async closeTire({commit}, payloadID) {
        try {
            commit(
                "closeTire",
                await Repository.get(`${TIRE_API}/close/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async openTire({commit}, payloadID) {
        try {
            commit(
                "openTire",
                await Repository.get(`${TIRE_API}/open/${payloadID}`)
            );
        } catch (error) {
            console.log(error);
        }
    },

    async deleteSelectedTires({commit}, deleteSelectedIDs) {
        try {
            let idString = ''
            deleteSelectedIDs.forEach(element => {
                idString += "&id="+element
            })
            commit(
                "deleteSelectedTires",
                await Repository.delete(`${TIRE_API}/deleteSelected?${idString}`)
            );
        } catch (error) {
            console.log(error);
        }

    },

    async searchTires({commit}, searchTerm) {
        try {
            commit(
                "loadAllTires",
                await Repository.get(`${TIRE_API}/search?q=${searchTerm}`)
            );
        } catch (error) {
            console.log(error);
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
