<template lang="pug">
  v-data-table(
    :headers="headers",
    :items="$store.state.tire.tire_data",
    item-key="id"
    :loading="loading",
    :single-select="false"
    show-select
    v-model="selectedTires"
  )
    v-progress-linear(slot='progress', height='2', :indeterminate='loading')
    template( v-slot:item.serial="{ item }")
      .d-flex.align-center.tyreComponent.py-1()
        image-thumbnail.mr-3(v-for="(itemID,index) in item.photos_id" :key="index" :width='60', :height='60', :src="itemID")
        .d-flex.flex-column()
          .font-weight-bold() {{item.serial}}
          .caption.grey--text() {{item.dot_id}}
          .caption() {{formatPurchaseDate(item.purchase_date)}}


    template( v-slot:item.vehicle_id="{ item }")
      vehicle-column-by-id(v-if="item.vehicle_id" :vehicle_id="item.vehicle_id")
    template( v-slot:item.tire_store_id="{ item }")
      tire-store-column-by-id(v-if="item.tire_store_id" :tireStore_id="item.tire_store_id")
    template( v-slot:item.tire_model_id="{ item }")
      tire-model-column-by-id(v-if="item.tire_model_id" :tireModel_id="item.tire_model_id")

    template( v-slot:item.cost="{ item }")
      .d-flex.flex-column()
        div() {{$store.state.profile.userInfo.account.region_settings.currency}}:
        .caption() CPK:

    template( v-slot:item.action="{ item }")
      v-row.mr-2(justify="end")
        more-drop-down-menu(:items='menuItems', @onClick='onClick', :theObject='item' )

</template>
<script>
import MoreDropDownMenu from "@/modules/global/components/lists/MoreDropDownMenu";
import MerchantColumnById from "@/modules/global/components/table/MerchantColumnById";
import ImageThumbnail from "@/modules/global/components/forms/ImageThumbnail";
import TireModelColumnById from "@/modules/global/components/table/TireModelColumnById.vue";
import VehicleColumnById from "@/modules/global/components/table/VehicleColumnById.vue";
import TireStoreColumnById from "@/modules/global/components/table/TireStoreColumnById";
import moment from "moment";
export default {
  props: ["loading"],
  components: {
    MoreDropDownMenu,
    MerchantColumnById,
    ImageThumbnail,
    TireModelColumnById,
    VehicleColumnById,
    TireStoreColumnById
  },
  computed: {
    selectedTires: {
      get: function () {
        return this.$store.getters["tire/getSelectedTires"];
      },
      set: function (value) {
        this.$store.commit("tire/setSelectedTires", value);
      }
    },

    menuItems: {
      get: function () {
        let result = [];
        result.push({title: "Edit", icon: "edit", action: "edit"});
        result.push({title: "Delete", icon: "delete", action: "delete"});
        return result;
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: "Serial",
        value: "serial_number",
        align: "left"
      },
      {
        text: "Model",
        value: "tire_model_id",
        align: "left"
      },
      {
        text: "Vehicle",
        value: "vehicle_id",
        align: "left"
      },
      {
        text: "Tire Store",
        value: "tire_store_id",
        align: "left"
      },
      {
        text: "Millage",
        value: "millage",
        align: "center"
      },
      {
        text: "Cost",
        value: "cost",
        align: "center"
      },
      {
        text: "Tread Depth (mm)",
        value: "tread_depth",
        align: "center"
      },
      {
        text: "Status",
        value: "status",
        align: "left"
      },

      {text: "Action", value: "action", tooltip: "Edit", align: "right"}



    ],

  }),

  methods:{

    formatPurchaseDate: function(value) {
      if (value==null || value==undefined){
        return null;
      }
      return moment(value).format("YYYY-MM-DD");
    },
    onClick: function (value) {
      console.log(value.action);
      switch (value.action) {
        case "edit":
          this.$store.commit("tire/setSelectedTires", value.theObject.id);



          this.$router.push({
            name: "tireForm",
            params: {inspectionID: value.theObject.id}
          }).catch(()=>{});
          break;
        case "delete":
          confirm("Are you sure you want to delete this item?") &&
          this.$store.dispatch("tire/deleteTire", value.theObject.id);
          break;
      }
    }
  }
}
</script>
<style scoped lang="stylus">

</style>