import { render, staticRenderFns } from "./IFrameLayout.vue?vue&type=template&id=027c1d6e&scoped=true&lang=pug"
import script from "./IFrameLayout.vue?vue&type=script&lang=js"
export * from "./IFrameLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027c1d6e",
  null
  
)

export default component.exports