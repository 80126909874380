<template lang="pug">
  .px-4()
    v-alert.px-2(v-if="errors.length" :value="true" color="error" icon="warning")
      div(v-for="e,index in errors" :key="index" v-text="e")
    v-layout.px-2(justify-end)
      v-btn.mr-2(small color="primary" text @click="goBack") Cancel
      v-btn(small color="primary" @click="save") Save
    v-card.my-4
      v-form(ref="form"  lazy-validation)
        v-flex(xl12)
          v-container(grid-list-sm)
            v-layout(row, wrap, justify-start)
              v-flex(md6, xs12, xl4)
                v-text-field(v-model="name"  :rules="[rules.required]" hint="Name" :persistent-hint="true"  label="Name of Store"  required)
              v-flex(md6, xs12, xl4)
                v-text-field(v-model.number="max_quantity" :rules="[rules.isWholeNumber]"   hint="Maximum Quantity of Goods (Optional)" :persistent-hint="true"  label="Max Quantity"  required)
              v-flex(md6, xs12, xl4)
                div() {{manager_id}}
                contact-auto-complete(v-model="manager_id" label="Store Manager" )

              v-flex(md6, xs12, xl4)
                v-text-field(v-model="city"  hint="City" :persistent-hint="true"  label="City"  required)
              v-flex(md6, xs12, xl4)
                v-text-field(v-model="region"  hint="Region" :persistent-hint="true"  label="Region"  required)
              v-flex(md6, xs12, xl4)
                v-text-field(v-model="country"  hint="Country" :persistent-hint="true"  label="Country"  required)

              v-flex(md6, xs12, xl4)
                  location-select(v-model='location' :width="400" :height="200" label="Location" description="Select Store location")
              v-flex(md6, xs12, xl4)
                  attach-fill-form( :schema="imagesSchama" :data="{value:photos_id}" @onChange="saveImages"  @onMenuAction="onMenuActionImages" :moreMenu="additionalImageMenuItems")




</template>
<script>
import LocationSelect from "@/modules/global/components/forms/LocationSelect";
import {createHelpers} from "vuex-map-fields";
import ContactAutoComplete from "@/modules/global/components/forms/ContactAutoComplete.vue";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm";

const {mapFields} = createHelpers({
  getterType: "tireStore/getTireStore",
  mutationType: "tireStore/updateTireStore",
});

const pattern = /^\d+$/;

export default {
  name: "TireStoreForm",
  components: {
    ContactAutoComplete,
    LocationSelect,
    AttachFillForm,
  },
  data: () => ({

    error: false,
    errors: [],
    rules: {
      required: value => !!value || "Required.",
      isWholeNumber: value => {
        return pattern.test(value) || "Must be a whole number";
      }
    },
    imagesSchama: {
      name: "Attach",
      id: "12345",
      value: [],
      label: "Upload Images",
      hint: null,
      type: "image",
      image_size: "medium",
      multiple: true,
    },
    additionalImageMenuItems: [
    ],
  }),
  mounted() {

  },
  computed:{
    ...mapFields(["name","city","region","country","location","max_quantity","manager_id","photos_id"]),
  },
  methods:{
    goBack: function(){
      this.$store.dispatch("tireStore/loadAllTireStores").then(() => {
        this.$router.back();
      })

    },
    save: function(){
      if (this.$refs.form.validate()){
        this.$store.dispatch("tireStore/saveTireStore").then(()=>{
          this.$store.commit("showSnackBar", {
            color: "accent",
            message: `Store "${this.name}" is saved`
          });

          // refresh the List
          this.$router.back();
          this.$store.dispatch("tireStore/loadAllTireStores");
        })
      }
    },
    saveImages: function (theObject) {
      this.$store.commit("tireStore/updateTireStore", {
        path: "photos_id",
        value: theObject.value,
      });
    },

    onMenuActionImages: function (value) {
      switch (value.action) {
        case "delete":
          this.$store.commit("tireStore/deleteImage", value.theObject.id);
          break;
      }
    },
  }
}
</script>
<style scoped lang="stylus">

</style>