import { render, staticRenderFns } from "./TireModelSettings.vue?vue&type=template&id=44f4ddf2&scoped=true&lang=pug"
import script from "./TireModelSettings.vue?vue&type=script&lang=js"
export * from "./TireModelSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f4ddf2",
  null
  
)

export default component.exports