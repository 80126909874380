<template lang="pug">
  div()
    v-divider
    v-container.pa-2(fluid)
      v-layout.my-2(row, wrap)
        h2.mx-4 Tire List
        v-spacer
        .text-xs-center
          v-menu(offset-y)
            template(#activator="{on}")
              v-btn.ma-2(small, outlined, color='primary', dark, v-on="on")
                | Actions
                v-icon(dark) keyboard_arrow_down
            v-list
              v-list-item-group(color="primary")
                v-list-item(v-for='item in items', :key='item.title')
                  v-list-item-title {{ item.title }}
        v-btn.ma-2(small, color='primary' @click="openTirePatternLayout")
          v-icon.pr-2(dark) tire_repair
        v-btn.ma-2(small, color='primary', dark, @click="openTireModelForm") Add Items
    v-divider
    v-layout.ma-4(row align-center)
      v-row.fill-width()
        .d-flex.mx-4.fill-width()
          .d-flex.align-center.fill-width()
            v-text-field.mr-3(v-model='search', label='Search Filter', persistent-hint, required, :append-icon="'search'" style="max-width:300px")
            v-spacer()
            v-btn.ml-4( color="primary" outlined ) Clear All {{this.$store.getters['inventory/item/getSelectedItems'].length}} Selected Suppliers
    v-divider
    .grey.lighten-4.py-3
    v-divider
    tire-table(:loading='loading')
    v-divider

</template>
<script>
import TireTable from "@/modules/inventory/tire_management/components/tables/TireTable.vue";

export default {
  components: {
    TireTable
  },
  data: () => ({
    loading: true,
    dialog: false,
    active: true,
    search: "",
    group: null,
    items: [
      {
        title: "Action 1"
      },
      {
        title: "Action 2"
      }
    ],

  }),
  mounted() {
    this.$store.dispatch("tire/loadAllTires").then(() => {
      this.loading = false;
    })
  },
  methods: {
    openTireModelForm() {
      this.$store.dispatch("tire/addTire").then(() => {
        this.$router.push({ name: "tireForm" , parmas: {itemID: this.$store.getters["tire/getCurrentTire"].id}});
      })
    },
    openTirePatternLayout() {
      this.$router.push({name: "tirePatternLayout"})
    }
  }
}
</script>
<style scoped lang="stylus">

</style>