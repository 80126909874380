<template lang="pug">
  div(v-if="$store.state.fuelCard.currentIndex > -1")
    v-layout.pt-2.pr-3(align-center)
      v-breadcrumbs(:items="getBreadCrumbItems()" divider="/")
      v-spacer
      v-btn.ma-2(small color="primary" outlined dark @click="cancel") cancel
      v-btn.ma-2(small color="primary" @click="save") save


    v-form(ref="form" v-model="valid" lazy-validation)
      v-container.py-2
        v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
          div(v-for="(e,index) in errors" :key="index" v-text="e")


      v-container
        v-card
          v-card-text
            v-container(grid-list-xl)
              form-divider FUEL CARD DETAILS
              v-row()

                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model='name', hint='ID of Card', persistent-hint, label='Identification Name of Card')

                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-text-field(v-model="description" label="Card Description" persistent-hint hint='card description hint')

                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  v-select(v-model="fuel_provider"   :items="providerList" )
                    template(v-slot:item="{ index, item }")
                      v-avatar(size="30")
                        img(:src="`/static/web/${item}.png`")
                      div.ml-2() {{item}}
                    template(v-slot:selection="{ item, index }")
                      v-avatar(size="30")
                        img(:src="`/static/web/${item}.png`")
                      div.ml-2() {{item}}


              form-divider ASSIGNMENT
              v-row()
                v-col.px-2(cols="12" xs="12" md="6" xl="4")

                  vehicles-auto-complete(v-model="vehicles_id"  :multiple="true")
                v-col.px-2(cols="12" xs="12" md="6" xl="4")
                  contacts-auto-complete(v-model="contacts_id"  :multiple="true")


</template>
<script>
import {createHelpers} from "vuex-map-fields";
import FormDivider from "@/modules/global/components/forms/FormDivider";
import VehiclesAutoComplete from "@/modules/global/components/forms/VehiclesAutoComplete";
import ContactsAutoComplete from "@/modules/global/components/forms/ContactsAutoComplete";

const {mapFields} = createHelpers({
    getterType: "fuelCard/",
  mutationType: "fuelCard/updateFuelCard"
});

export default {
  components: {
    FormDivider,
    VehiclesAutoComplete,
    ContactsAutoComplete
  },
  data: () => ({
    error: false,
    errors: [],
    valid: true,

    providerList: ["kobil", "mobil", "bp", "total", "shell"]
  }),

  mounted() {
    if (this.$store.state.fuelCard.currentIndex == -1) {
      this.$store.dispatch("fuelCard/fetchFuelCard", this.$route.params.fuelID);
    }
  },

  computed: {
    ...mapFields([
      "name",
      "description",
      "fuel_provider",
      "vehicles_id",
      "contacts_id"
    ])
  },

  methods: {
    cancel: function () {
      this.$router.push({name: "fuels", params: {tabID: 1}}).catch(()=>{});
    },

    save: function () {
      this.$store.dispatch("fuelCard/saveFuelCard").then(() => {
        this.$router.push({name: "fuels", params: {tabID: 1}}).catch(()=>{});
      });
    },

    getBreadCrumbItems: function () {
      var theArray = [];
      theArray.push({
        text: "Card List",
        disabled: false,
        to: {name: "fuels", params: {tabID: 1}}
      });
      theArray.push({
        text: "Card Entry",
        disabled: true
      });
      return theArray;
    }
  }
};
</script>
<style lang="stylus" scoped></style>
